import React, { useEffect, useState, memo } from "react";
import { Row, Col } from "antd";
import { getPdfdetails } from "../services/ApiService";
import { message } from "antd";

function camelToNormal(text) {
  let str = text.replace(/([a-z])([A-Z])/g, "$1 $2").toUpperCase();

  return str.split("_").join(" ");
}

const renderData = (key, data) => {
  if (data.length > 0) {
    return (
      <div
        key={key}
        className="p-2 rounded-lg shadow-md bg-white"
        style={{
          borderBottom: "1px dashed #ccc",
          paddingBottom: "1rem",
        }}
      >
        <h3
          className="text-gray-800 pb-1"
          style={{ fontWeight: "600", fontSize: "18px" }}
        >
          {key.charAt(0).toUpperCase() + key.slice(1)} Details:
        </h3>
        {data.map((item, index) => (
          <div
            key={index}
            className="text-sm mb-2 bg-gray-100"
            style={{
              fontSize: "11px",
              border: "1px solid #eee",
              borderRadius: "5px",
              padding: "0.5rem",
              transition: "background-color 0.3s",
            }}
          >
            <Row gutter={[16, 16]}>
              {Object.keys(item).map((itemKey, i) => (
                <Col span={12} key={i}>
                  <span className="mr-2" style={{ fontWeight: "600" }}>
                    {camelToNormal(itemKey)}:
                  </span>
                  {item[itemKey]}
                </Col>
              ))}
            </Row>
          </div>
        ))}
      </div>
    );
  }
};

const DownloadFileTravel = React.memo(({ data }) => {
  return (
    <div className="max-w-3xl mx-auto p-4 border border-gray-300 rounded-lg shadow-md bg-white">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: "1rem",
          borderBottom: "1px dashed #ccc",
          marginBottom: "1rem",
          gap: "1rem",
        }}
      >
        <img
          style={{ width: "4rem" }}
          src="sidebarcosten.png"
          alt="Company Logo"
        />
        <h2
          className="text-lg font-semibold"
          style={{
            textAlign: "center",
            fontSize: "20px",
          }}
        >
          Travel Request
        </h2>
      </div>

      {Object.keys(data)?.map((key) => renderData(key, data[key]))}

      <p className="text-center mt-6" style={{ fontSize: "12px" }}>
        Thank you for choosing Costen
      </p>
    </div>
  );
});

export default DownloadFileTravel;
