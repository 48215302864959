import {
  Button,
  Col,
  Form,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Switch,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { perdiem, perdiemextra } from "../../../redux/actions";
import DatePicker from "react-datepicker";
import "./pullperdiem.css";
import "react-datepicker/dist/react-datepicker.css";
import {
  ExpenseCancelRequest,
  cancelRequest,
  clearRequest,
  countryNameExpenseAdmin,
  expenseClear,
  expensePerDiemRequest,
  getExpensePerDiem,
  getPerDiem,
  perDiemRequest,
} from "../../../services/ApiService";
import { useNavigate } from "react-router-dom";
import { MdCancel } from "react-icons/md";
import { FaCircle } from "react-icons/fa";

const initialFormData = {
  date: null,
  country: "",
  entitlement: "",
  currencyCode: "",
  exchangeRate: "",
  dailyAllowance: "",
  breakfast: 0,
  lunch: 0,
  dinner: 0,
};

function PullPerDiem({ triggerParentEffect }) {
  const idCounter = useRef(1);
  const [recoveryAmount, setRecoveryAmount] = useState(0);
  const requestid = useSelector((state) => state.requestedid);
  const requestName = useSelector((state) => state.travelHeader.requestName);
  const dates = useSelector((state) => state.travelHeader.dates);
  const dispatch = useDispatch();
  const [array, setArray] = useState([]);
  const userType = sessionStorage.getItem("userType");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [perDiemAmount, setPerDiemAmount] = useState(0);

  const requestPolicy = useSelector(
    (state) => state.travelHeader.requestPolicy
  );
  const [formData, setFormData] = useState([{ ...initialFormData, id: 0 }]);
  function formatDate(date) {
    if (date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${day}-${month}-${year}`;
    }
    return "";
  }
  const getNewForm = () => ({
    ...initialFormData,
    id: idCounter.current++,
  });

  const handleAddForm = () => {
    setFormData([...formData, getNewForm()]);
  };

  const handleRemoveForm = (index) => {
    const newFormData = [...formData];
    newFormData.splice(index, 1);
    setFormData(newFormData);
  };
  useEffect(() => {
    const totalDailyAllowance = formData.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.dailyAllowance;
    }, 0);
    setPerDiemAmount(totalDailyAllowance);
    const totalRecoveryAmount = calculateTotalRecovery(formData);
    setRecoveryAmount(totalRecoveryAmount);
  }, [formData]);
  useEffect(() => {
    countryNameExpenseAdmin().then((res) => {
      if (res.responseCode === 200) {
        setCountryOptions(res.data);
      }
    });
    getExpensePerDiem(requestid, requestPolicy).then((res) => {
      if (res.responseCode === 200) {
        if (res.data) {
          const initialFormDataArray = res.data.map((obj) => ({
            date: obj.diemDate !== null ? new Date(obj?.diemDate) : null,
            country: obj.country,
            entitlement: obj.entitlement,
            currencyCode: obj.currencyCode,
            exchangeRate: obj.exchangeRate,
            dailyAllowance: obj.dailyAllowance,
            breakfast: obj.breakfast,
            lunch: obj.lunch,
            dinner: obj.dinner,
          }));
          setFormData(initialFormDataArray);
          //   if (res.data.length > 0) {
          //     const convertedArray = res.data.map((obj) => {
          //       const date = new Date(obj.date);
          //       const yyyy = date.getFullYear();
          //       const mm = String(date.getMonth() + 1).padStart(2, "0");
          //       const dd = String(date.getDate()).padStart(2, "0");

          //       return {
          //         date: `${yyyy}-${mm}-${dd}`,
          //         breakfast: obj.breakfast,
          //         lunch: obj.lunch,
          //         dinner: obj.dinner,
          //       };
          //     });
          //     setArray(convertedArray);
          //   } else {
          //     const arrayOfObjects = dates?.map((date) => ({
          //       date,
          //       breakfast: 0,
          //       lunch: 0,
          //       dinner: 0,
          //     }));
          //     setArray(arrayOfObjects);
          //   }
        }
      } else {
        message.error(res.responseMessage);
      }
    });
  }, []);
  const onFinish = (value) => {
    const formattedData = formData.map((data) => ({
      ...data,
      date: formatDate(data.date),
      breakfast: data.breakfast ? data.breakfast : 0,
      lunch: data.lunch ? data.lunch : 0,
      dinner: data.dinner ? data.dinner : 0,
    }));
    let body = {
      requestId: requestid,

      diems: formattedData,
    };
    let hasNaN = false;

    formattedData.forEach((obj) => {
      if (isNaN(obj.dailyAllowance)) {
        hasNaN = true;
      }
    });

    if (hasNaN) {
      message.info("Please fill the amount properly");
    } else {
      expensePerDiemRequest(body, requestPolicy).then((res) => {
        if (res.responseCode === 200) {
          triggerParentEffect(body);
          message.success("Saved");
        } else {
          message.error(res.responseMessage);
        }
      });
    }
  };

  // const handleCheckboxChange = (event, rowIndex, propertyName) => {
  //   const updatedData = [...array];
  //   updatedData[rowIndex][propertyName] = event.target.checked ? 1 : 0;
  //   setArray(updatedData);
  // };
  const onCancel = () => {
    setOpen(true);
  };
  const onSubmit = () => {
    setOpen(false);
    let body = {
      requestId: requestid,
    };
    if (requestid) {
      ExpenseCancelRequest(body).then((res) => {
        if (res.responseCode === 200) {
          message.success("Canceled the Request Successfully");
          navigate("/dashboard-expense");
        } else {
          message.error(res.responseMessage);
        }
      });
    } else {
      navigate("/dashboard-expense");

      message.error("Data is not Saved Yet");
    }
  };
  const onClear = () => {
    if (requestid) {
      setFormData([{ ...initialFormData, id: 0 }]);
      let body = {
        requestId: requestid,
        requestType: "perdiem",
      };
      expenseClear(body).then((res) => {
        if (res.responseCode === 200) {
          triggerParentEffect(body);

          message.success("Per Diem Data Cleared Successfully");
        } else {
          message.error(res.responseMessage);
        }
      });
    } else {
      message.info("Please Save Travel Overview Data");
    }
  };
  const handleDateChange = (index, field, date) => {
    const newFormData = [...formData];
    newFormData[index][field] = date;
    setFormData(newFormData);
  };

  const handleInputChange = (index, field, value) => {
    if (field === "country") {
      const selectedCountryObject = countryOptions.find(
        (item) => item.countryName === value
      );
      const newFormData = [...formData];
      newFormData[index]["entitlement"] = selectedCountryObject.entitlement;
      newFormData[index]["currencyCode"] = selectedCountryObject.currencyCode;
      newFormData[index]["exchangeRate"] = selectedCountryObject.exchangeRate;

      setFormData(newFormData);

      if (
        newFormData[index]["entitlement"] &&
        newFormData[index]["exchangeRate"]
      ) {
        newFormData[index]["dailyAllowance"] =
          newFormData[index]["entitlement"] *
          newFormData[index]["exchangeRate"];
      }
    } else if (field === "exchangeRate") {
      const newFormData = [...formData];
      newFormData[index][field] = value;
      newFormData[index]["dailyAllowance"] =
        newFormData[index]["entitlement"] *
        parseInt(newFormData[index]["exchangeRate"]);

      if (
        newFormData[index]["entitlement"] &&
        newFormData[index]["exchangeRate"]
      ) {
        newFormData[index]["dailyAllowance"] =
          newFormData[index]["entitlement"] *
          newFormData[index]["exchangeRate"];
      }
    }
    const newFormData = [...formData];
    newFormData[index][field] = value;
    setFormData(newFormData);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };
  function calculateRecoveryAmount(mealObj) {
    let recoveryAmount = 0;

    // Calculate recovery for breakfast
    if (mealObj.breakfast === 1) {
      recoveryAmount += 0.2 * mealObj.dailyAllowance;
    }

    // Calculate recovery for lunch
    if (mealObj.lunch === 1) {
      recoveryAmount += 0.3 * mealObj.dailyAllowance;
    }

    // Calculate recovery for dinner
    if (mealObj.dinner === 1) {
      recoveryAmount += 0.5 * mealObj.dailyAllowance;
    }

    return recoveryAmount;
  }
  function calculateTotalRecovery(mealsArray) {
    let totalRecovery = 0;

    // Loop through each object in the array
    mealsArray.forEach((mealObj) => {
      // Calculate recovery amount for the current object
      const recoveryAmount = calculateRecoveryAmount(mealObj);
      // Add recovery amount to the total
      totalRecovery += recoveryAmount;
    });

    return totalRecovery;
  }
  return (
    <div
      style={{
        backgroundColor: "white",
        margin: "1.5rem 1.5rem 0 0",
        display: "flex",
        justifyContent: "flex-start",
        borderRadius: "5px",
        padding: "1rem 1rem 1rem 2rem",
        flexDirection: "column",
      }}
    >
      <>
        <div
          style={{
            height: "54vh",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between", // Ensures content is spaced evenly
              alignItems: "center", // Align items vertically
              marginBottom: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "0.5rem",
              }}
            >
              <span style={{ fontWeight: "600" }}>Request ID :</span>
              <span style={{ color: "#3052D0", fontWeight: "500" }}>
                {requestid ? requestid : ""}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "0.5rem",
                marginLeft: "1rem",
              }}
            >
              <span style={{ fontWeight: "600" }}>Request Name :</span>
              <span style={{ color: "#3052D0", fontWeight: "500" }}>
                {requestName ? requestName : ""}
              </span>
            </div>
            <div
              style={{
                // marginLeft: "auto", // Pushes this div to the rightmost area
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginRight: "3.5rem",
              }}
            >
              <span style={{ fontWeight: "600" }}>Per Diem Amount :</span>
              <span
                style={{
                  color: "#3052D0",
                  fontWeight: "500",
                  marginLeft: "0.2rem",
                }}
              >
                {perDiemAmount ? perDiemAmount : 0}
                {/* {additionalInfo ? additionalInfo : ""} */}
              </span>
            </div>
            <div
              style={{
                // marginLeft: "auto", // Pushes this div to the rightmost area
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginRight: "3.5rem",
              }}
            >
              <span style={{ fontWeight: "600" }}>Recovery Amount :</span>
              <span
                style={{
                  color: "#E93B77",
                  fontWeight: "500",
                  marginLeft: "0.2rem",
                }}
              >
                {recoveryAmount ? recoveryAmount : 0}
                {/* {additionalInfo ? additionalInfo : ""} */}
              </span>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: "0.3rem" }}>
            <span style={{ fontWeight: "600" }}>Per Diem</span>
            <span style={{ fontSize: "12px", color: "#7B809A" }}>
              (*check mark only those box where it was paid by Company)
            </span>
          </div>
          <div>
            {dates && (
              <Row style={{ marginBottom: "0.3rem" }}>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "700",
                  }}
                  span={3}
                >
                  Date
                </Col>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "700",
                  }}
                  span={3}
                >
                  Country
                </Col>
                <Col
                  style={{
                    display: "flex",
                    // justifyContent: "center",
                    fontWeight: "700",
                  }}
                  span={2}
                >
                  Entitlement
                </Col>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "700",
                  }}
                  span={3}
                >
                  Currency
                </Col>
                <Col
                  style={{
                    display: "flex",
                    marginLeft: "0.7rem",

                    // justifyContent: "center",
                    fontWeight: "700",
                  }}
                  span={3}
                >
                  Exchange Rate
                </Col>
                <Col
                  style={{
                    display: "flex",
                    marginLeft: "0.5rem",
                    // justifyContent: "center",
                    fontWeight: "700",
                  }}
                  span={3}
                >
                  Daily Allowance
                </Col>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "700",
                  }}
                  span={2}
                >
                  Breakfast
                </Col>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "700",
                  }}
                  span={2}
                >
                  Lunch
                </Col>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "700",
                  }}
                  span={2}
                >
                  Dinner
                </Col>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "700",
                  }}
                  span={1}
                ></Col>
              </Row>
            )}
            <div
              style={{ height: "28vh", overflow: "auto" }}
              className="scrollable-container"
            >
              {formData?.map((form, index) => (
                <Row style={{ marginBottom: "0.5rem", paddingBottom: "0" }}>
                  <Col
                    style={{ display: "flex", justifyContent: "center" }}
                    span={3}
                  >
                    <FaCircle
                      style={{
                        color: "#3052D0",
                        fontSize: "15px",
                        marginTop: "0.7rem",
                        marginRight: "0.6rem",
                      }}
                    />
                    <DatePicker
                      className="inputclassperdiem"
                      selected={form.date}
                      onChange={(date) => handleDateChange(index, "date", date)}
                      placeholderText="Select Date"
                    />
                  </Col>
                  <Col
                    style={{ display: "flex", justifyContent: "center" }}
                    span={3}
                  >
                    <Select
                      value={form.country}
                      listItemHeight={10}
                      listHeight={200}
                      showSearch
                      onChange={(value) =>
                        handleInputChange(index, "country", value)
                      }
                      onSearch={onSearch}
                      filterOption={filterOption}
                      placeholder="Select Country"
                      style={{ width: "100px" }}
                      virtual={true}
                    >
                      {countryOptions?.map((option) => (
                        <Select.Option
                          key={option.countryName}
                          value={option.countryName}
                        >
                          {option.countryName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                  <Col
                    style={{ display: "flex", justifyContent: "center" }}
                    span={2}
                  >
                    <input
                      disabled
                      className="inputclass"
                      style={{ width: "101%" }}
                      type="text"
                      value={form.entitlement}
                    />
                  </Col>
                  <Col
                    style={{ display: "flex", justifyContent: "center" }}
                    span={3}
                  >
                    <input
                      disabled
                      className="inputclass"
                      style={{
                        width: "100%",
                        marginLeft: "0.6rem",
                        marginRight: "0.3rem",
                      }}
                      type="text"
                      value={form.currencyCode}
                    />
                  </Col>

                  <Col
                    style={{ display: "flex", justifyContent: "center" }}
                    span={3}
                  >
                    <input
                      className="inputclass"
                      style={{ width: "100%", marginLeft: "0.6rem" }}
                      type="text"
                      value={form.exchangeRate}
                      onChange={(e) =>
                        handleInputChange(index, "exchangeRate", e.target.value)
                      }
                    />
                  </Col>
                  <Col
                    style={{ display: "flex", justifyContent: "center" }}
                    span={3}
                  >
                    <input
                      disabled
                      className="inputclass"
                      style={{ width: "100%", marginLeft: "0.9rem" }}
                      type="text"
                      value={form.dailyAllowance}
                    />
                  </Col>
                  <Col
                    style={{ display: "flex", justifyContent: "center" }}
                    span={2}
                  >
                    <input
                      style={{ marginLeft: "50%" }}
                      type="checkbox"
                      checked={form.breakfast === 1}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "breakfast",
                          e.target.checked ? 1 : 0
                        )
                      }
                    />
                  </Col>
                  <Col
                    style={{ display: "flex", justifyContent: "center" }}
                    span={2}
                  >
                    <input
                      type="checkbox"
                      style={{ marginLeft: "50%" }}
                      checked={form.lunch === 1}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "lunch",
                          e.target.checked ? 1 : 0
                        )
                      }
                    />
                  </Col>
                  <Col
                    span={2}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <input
                      type="checkbox"
                      style={{ marginLeft: "50%" }}
                      checked={form.dinner === 1}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "dinner",
                          e.target.checked ? 1 : 0
                        )
                      }
                    />
                  </Col>
                  <Col
                    span={1}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <MdCancel
                      onClick={() => handleRemoveForm(index)}
                      style={{
                        color: "red",
                        fontSize: "18px",
                        marginTop: "0.5rem",
                        cursor: "pointer",
                      }}
                    />
                  </Col>
                </Row>
              ))}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                onClick={handleAddForm}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  gap: "0.5rem",
                  boxShadow: "0px 2px 6px 0px rgba(151, 172, 198, 0.25)",
                  padding: "0.5rem",
                  width: "17%",
                  borderRadius: "60px",
                  marginTop: "0.2rem",
                  cursor: "pointer",
                }}
              >
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Group 44">
                    <rect
                      id="bg - icon"
                      width="32"
                      height="32"
                      rx="16"
                      fill="#534ECC"
                    />
                    <path
                      id="Vector"
                      d="M19.6539 15.1538H15.8463V11.3462C15.8463 11.2543 15.8098 11.1663 15.7449 11.1014C15.68 11.0365 15.5919 11 15.5001 11C15.4083 11 15.3203 11.0365 15.2554 11.1014C15.1904 11.1663 15.154 11.2543 15.154 11.3462V15.1538H11.3464C11.2546 15.1538 11.1665 15.1903 11.1016 15.2552C11.0367 15.3201 11.0002 15.4082 11.0002 15.5C10.9985 15.545 11.0065 15.5898 11.0236 15.6315C11.0406 15.6731 11.0664 15.7106 11.0993 15.7415C11.1321 15.7723 11.1711 15.7957 11.2137 15.8102C11.2563 15.8247 11.3016 15.8299 11.3464 15.8254H15.154V19.6538C15.154 19.7457 15.1904 19.8337 15.2554 19.8986C15.3203 19.9635 15.4083 20 15.5001 20C15.5919 20 15.68 19.9635 15.7449 19.8986C15.8098 19.8337 15.8463 19.7457 15.8463 19.6538V15.8462H19.6539C19.7457 15.8462 19.8337 15.8097 19.8986 15.7448C19.9635 15.6799 20 15.5918 20 15.5C20 15.4082 19.9635 15.3201 19.8986 15.2552C19.8337 15.1903 19.7457 15.1538 19.6539 15.1538Z"
                      fill="white"
                    />
                  </g>
                </svg>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.3rem",
                  }}
                >
                  <button
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      fontSize: "14px",
                      fontWeight: "600",
                      padding: "0",
                      cursor: "pointer",
                      marginTop: "0.5rem",
                    }}
                  >
                    Add another row
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{ display: "flex", justifyContent: "center", gap: "1.5rem" }}
        >
          <Button
            style={{
              width: "8.5rem",
              backgroundColor: "#3052D0",
              border: "none",
              color: "white",
            }}
            onClick={() => onFinish()}
          >
            Save
          </Button>
          <Button
            style={{
              width: "8.5rem",
              backgroundColor: "red",
              border: "none",
              color: "white",
            }}
            onClick={() => onClear()}
          >
            Clear
          </Button>
          <Popconfirm
            title="Are you sure, want to Cancel the whole request"
            okText="Yes"
            cancelText="No"
            // description="Open Popconfirm with Promise"
            onConfirm={onSubmit}
            // onCancel={() => setOpen(false)}
          >
            <Button
              style={{
                width: "8.5rem",
                backgroundColor: "transparent",
                border: "1px solid red",
                color: "red",
              }}
            >
              Cancel
            </Button>
          </Popconfirm>
        </div>
      </>
    </div>
  );
}

export default PullPerDiem;
