
const initialState = {};

const travelAdvanceExpense= (state = initialState, action) => {
  switch (action.type) {
      case "ADVANCE":
        return action.payload;
    default:
      return state;
  }
};

export default (travelAdvanceExpense);
