import React from "react";
import ReactDOM from "react-dom";

const PortalComponent = ({ children }) => {
  const portalRoot = document.createElement("div");
  portalRoot.style.position = "absolute";
  portalRoot.style.top = "-9999px"; // Move it off-screen
  portalRoot.style.left = "-9999px"; // Move it off-screen

  // Append the portalRoot to the body
  React.useEffect(() => {
    document.body.appendChild(portalRoot);
    return () => {
      document.body.removeChild(portalRoot);
    };
  }, [portalRoot]);

  return ReactDOM.createPortal(children, portalRoot);
};

export default PortalComponent;
