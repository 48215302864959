import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Spin, message } from "antd";
import Header from "./Header";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
// import "./datepicker.css";
import "../../src/items.css";
import "./expensedashboard.css";
import {
  amount,
  bus,
  carRental,
  cashAdvance,
  claimno,
  flight,
  hoteldata,
  perdiem,
  perdiemextra,
  requesteid,
  taxi,
  train,
  travelAdvanceExpense,
  travelHeader,
} from "../redux/actions";
import { useDispatch } from "react-redux";
import ExpenseAdminSideBar from "./ExpenseAdminSideBar";
import {
  businessUnitExpenseAdmin,
  costCenterExpenseAdmin,
  countryNameExpenseAdmin,
  employeeIdExpenseAdmin,
  financePersonExpenseAdmin,
  managerIdExpenseAdmin,
  onBoard,
} from "../services/ApiService";
import PageNotFound from "./PageNotFound";
function formatDate(date) {
  if (date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  return "";
}
function ExpenseAdminDashboard() {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const [dateOfJoining, setDateOfJoining] = useState();
  const [countryName, setCountryName] = useState([]);
  const [costCenter, setCostCenter] = useState([]);
  const [managerId, setManagerId] = useState([]);
  const [form] = Form.useForm();
  const [businessUnit, setBusinessUnit] = useState([]);
  const [departmentId, setDepartmentId] = useState([]);
  const [employeeId, setEmployeeId] = useState([]);
  const [financePerson, setFinancePerson] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const adminRights = sessionStorage.getItem("aSF");
  useEffect(() => {
    countryNameExpenseAdmin().then((res) => {
      if (res.responseCode === 200) {
        setCountryName(res.data);
      } else {
        message.error(res.responseMessage);
      }
    });
    costCenterExpenseAdmin().then((res) => {
      if (res.responseCode === 200) {
        setCostCenter(res.data);
      } else {
        // message.error(res.responseMessage);
      }
    });
    let header = {
      organization: sessionStorage.getItem("organization"),
      expenseAdmin: sessionStorage.getItem("employeeId"),
    };
    managerIdExpenseAdmin(header).then((res) => {
      if (res.responseCode === 200) {
        setManagerId(res.data);
      } else {
        // message.error(res.responseMessage);
      }
    });
    businessUnitExpenseAdmin().then((res) => {
      if (res.responseCode === 200) {
        setBusinessUnit(res.data);
      } else {
        // message.error(res.responseMessage);
      }
    });
    financePersonExpenseAdmin().then((res) => {
      if (res.responseCode === 200) {
        setFinancePerson(res.data);
      } else {
        // message.error(res.responseMessage);
      }
    });
  }, []);
  const countryNameChange = (value) => {
    const selectedCountryObject = countryName.find(
      (item) => item.countryName === value
    );
    form.setFieldsValue({
      employeeCurrencyName: selectedCountryObject.currencyName,
      employeeCurrencyCode: selectedCountryObject.currencyCode,
    });
  };
  const financeContactPersonChange = (value) => {
    const selectedCountryObject = financePerson.find(
      (item) => item.employeeId === value
    );
    form.setFieldsValue({
      financeContactPersonEmail: selectedCountryObject.emailId,
    });
  };
  const businessUnitChange = (value) => {
    const selectedCountryObject = businessUnit.find(
      (item) => item.businessUnit === value
    );
    setDepartmentId(selectedCountryObject.departments);
  };

  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };
  useEffect(() => {
    dispatch(bus({}));
    dispatch(cashAdvance({}));
    dispatch(carRental({}));
    dispatch(flight({}));
    dispatch(hoteldata({}));
    dispatch(perdiem({}));
    dispatch(perdiemextra({}));
    dispatch(taxi({}));
    dispatch(train({}));
    dispatch(travelHeader({}));
    dispatch(requesteid(""));
    dispatch(claimno(""));
    dispatch(amount(0));
    dispatch(travelAdvanceExpense({}));

    const checkScreenSize = () => {
      setCollapsed(window.innerWidth < 768);
    };
    checkScreenSize();

    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  const handleDateChange = (date) => {
    setDateOfJoining(date);
  };
  const onFinish = (value) => {
    setSpinner(true);
    value["expenseAdministrator"] = sessionStorage.getItem("employeeId");
    value["expenseAdministratorEmail"] = sessionStorage.getItem("emailId");
    value["organization"] = sessionStorage.getItem("organization");
    value["dateOfJoining"] = formatDate(value.dateOfJoining);
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        value[key] = value[key] !== undefined ? value[key] : "";
      }
    }
    // employeeMiddleName
    if (
      Object.entries(value).every(
        ([key, field]) => key === "employeeMiddleName" || field !== ""
      )
    ) {
      onBoard(value).then((res) => {
        if (res.responseCode === 200) {
          setSpinner(false);
          message.info("User Id and Password have been shared with Employee");
          form.resetFields();
          setDateOfJoining();
        } else {
          setSpinner(false);
          message.error(res.responseMessage);
        }
      });
    } else {
      setSpinner(false);
      message.error("All Fields Required");
    }
  };
  const changeManagerName = (value) => {
    const selectedCountryObject = managerId.find(
      (item) => item.employeeId === value
    );
    form.setFieldsValue({
      managerId: selectedCountryObject.employeeId,
    });
  };

  return (
    <>
      {adminRights === "2" || adminRights === "3" ? (
        <div style={{ height: "100vh", backgroundColor: "#F7F8FA" }}>
          <Row>
            <Col
              xs={!collapsed ? 19 : 11}
              sm={!collapsed ? 10 : 6}
              md={!collapsed ? 7 : 4}
              lg={!collapsed ? 4 : 5}
              xl={!collapsed ? 5 : 3}
            >
              <div
                style={{ position: "fixed", zIndex: "1" }}
                className={`toggle ${collapsed ? "collapsed" : ""}`}
                onClick={toggleSidebar}
              >
                <svg
                  className={`arrow ${collapsed ? "collapsed" : ""}`}
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.39635 2.69038C9.19753 2.49709 8.8752 2.49709 8.67638 2.69038L4.60366 6.64997C4.50818 6.74279 4.45455 6.86869 4.45455 6.99996C4.45455 7.13122 4.50818 7.25712 4.60366 7.34994L8.67638 11.3095C8.8752 11.5028 9.19753 11.5028 9.39635 11.3095C9.59516 11.1162 9.59516 10.8029 9.39635 10.6096L5.6836 6.99996L9.39635 3.39034C9.59516 3.19705 9.59516 2.88367 9.39635 2.69038Z"
                    fill="black"
                  />
                </svg>
              </div>
              <ExpenseAdminSideBar collapsed={collapsed} id={"1"} />
            </Col>

            <Col
              xs={!collapsed ? 5 : 13}
              sm={!collapsed ? 14 : 18}
              md={!collapsed ? 17 : 20}
              lg={!collapsed ? 20 : 19}
              xl={!collapsed ? 19 : 21}
            >
              <Header expense={false} travel={true} />

              <Spin spinning={spinner} tip="On Boarding..." size="large">
                <Form form={form} onFinish={onFinish} layout="horizontal">
                  {/* <div style={{fontSize:'19px',fontWeight:'600',marginTop:'1rem'}}>
                On-Board
              </div> */}
                  <div
                    style={{
                      marginTop: "1rem",
                      height: "70vh",
                      overflowX: "hidden",
                      // overflowY: "auto",
                      marginRight: "0.2rem",
                    }}
                    className="scrollable-container"
                  >
                    {/* <Row gutter={6}>
                      <Col lg={4}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label style={{ fontWeight: "600" }}>
                            Organization:
                          </label>
                          <Input
                            disabled
                            value={sessionStorage.getItem("organization")}
                          />
                        </div>
                      </Col>
                      <Col lg={5}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label style={{ fontWeight: "600" }}>Admin ID:</label>
                          <Input
                            disabled
                            value={sessionStorage.getItem("employeeId")}
                          />
                        </div>
                      </Col>
                      <Col lg={5}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label style={{ fontWeight: "600" }}>
                            Admin Email:
                          </label>
                          <Input
                            disabled
                            value={sessionStorage.getItem("emailId")}
                          />
                        </div>
                      </Col>
                      <Col lg={5}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label style={{ fontWeight: "600" }}>
                            Employee ID:
                          </label>
                          <Form.Item name="employeeId">
                            <Input placeholder="Enter Employee Id" />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col lg={5}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label style={{ fontWeight: "600" }}>
                            Employee First Name:
                          </label>
                          <Form.Item name="employeeFirstName">
                            <Input placeholder="Enter Employee First Name" />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={6}>
                      <Col lg={5}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label style={{ fontWeight: "600" }}>
                            Employee Middle Name:
                          </label>
                          <Form.Item name="employeeMiddleName">
                            <Input placeholder="Enter Employee Middle Name" />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col lg={5}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label style={{ fontWeight: "600" }}>
                            Employee Last Name:
                          </label>
                          <Form.Item name="employeeLastName">
                            <Input placeholder="Enter Employee Last Name" />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label style={{ fontWeight: "600" }}>Email ID:</label>
                          <Form.Item name="emailId">
                            <Input placeholder="Enter Employee Email ID" />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col lg={5}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label style={{ fontWeight: "600" }}>
                            Date Of Joining:
                          </label>
                          <Form.Item name="dateOfJoining">
                            <DatePicker
                              style={{ width: "10%" }}
                              selected={dateOfJoining}
                              onChange={(date) => handleDateChange(date)}
                              className="form-control"
                              placeholderText="Select Joining Date"
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col lg={5}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label style={{ fontWeight: "600" }}>
                            Employee Business Title:
                          </label>
                          <Form.Item name="employeeBusinessTitle">
                            <Input placeholder="Enter Employee Business Title" />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={6}>
                    
                      <Col lg={4}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label style={{ fontWeight: "600" }}>
                            Bank Name:
                          </label>
                          <Form.Item name="bankDetails">
                            <Input placeholder="Enter Bank Name" />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label style={{ fontWeight: "600" }}>
                            Bank Code Name:
                          </label>
                          <Form.Item name="bankCodeName">
                            <Select placeholder="Select Bank Code Name">
                              <Select.Option key="IFSC" value="IFSC">
                                IFSC
                              </Select.Option>
                              <Select.Option key="MICR" value="MICR">
                                MICR
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col lg={5}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label style={{ fontWeight: "600" }}>
                            Bank Code Number:
                          </label>
                          <Form.Item name="ifscMicrOtherCode">
                            <Input placeholder="Enter Bank Code Number" />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col lg={5}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label style={{ fontWeight: "600" }}>
                            Bank Acc. Number:
                          </label>
                          <Form.Item name="bankAccountNumber">
                            <Input placeholder="Enter Acc. Number" />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label style={{ fontWeight: "600" }}>
                            Employee Name in Bank Record:
                          </label>
                          <Form.Item name="employeeNameInBankRecord">
                            <Input placeholder="Enter Employee Name in Bank Record" />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={6}>
                      <Col lg={5}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label style={{ fontWeight: "600" }}>
                            Country Name:
                          </label>
                          <Form.Item name="employeeCountryName">
                            <Select
                              listItemHeight={10}
                              listHeight={200}
                              showSearch
                              onChange={(value) => countryNameChange(value)}
                              filterOption={filterOption}
                              placeholder="Select Country Name"
                              // style={{ width: 200 }}
                              virtual={true}
                            >
                              {countryName?.map((option) => (
                                <Select.Option
                                  key={option.countryName}
                                  value={option.countryName}
                                >
                                  {option.countryName}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col lg={5}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label style={{ fontWeight: "600" }}>
                            Employee Currency Name:
                          </label>
                          <Form.Item name="employeeCurrencyName">
                            <Input
                              disabled
                              placeholder="Enter Employee Currency Name"
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col lg={5}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label style={{ fontWeight: "600" }}>
                            Employee Currency Code:
                          </label>
                          <Form.Item name="employeeCurrencyCode">
                            <Input
                              disabled
                              placeholder="Enter Employee Currency Code"
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col lg={5}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label style={{ fontWeight: "600" }}>
                            Manager Name:
                          </label>
                          <Form.Item name="managerId">
                            <Select
                              listItemHeight={10}
                              listHeight={200}
                              showSearch
                              onChange={(value)=>{changeManagerName(value)}}
                              filterOption={filterOption}
                              placeholder="Select Manager Name"
                              // style={{ width: 200 }}
                              virtual={true}
                            >
                              {managerId?.map((option) => (
                                <Select.Option
                                  key={option.employeeId}
                                  value={option.employeeId}
                                >
                              {option.managerName}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label style={{ fontWeight: "600" }}>
                         Manager ID:
                          </label>
                          <Form.Item name="managerId">
                            <Input
                              disabled
          
                            />
                          </Form.Item>
                        </div>
                      </Col>
              
                    </Row>
                    <Row gutter={6}>
                    <Col lg={4}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label style={{ fontWeight: "600" }}>
                            L1 Manager ID:
                          </label>
                          <Form.Item name="l1ManagerId">
                            <Select
                              listItemHeight={10}
                              listHeight={200}
                              showSearch
                              filterOption={filterOption}
                              placeholder="Select L1 Manager ID"
                              // style={{ width: 164 }}
                              virtual={true}
                            >
                              {managerId?.map((option) => (
                                <Select.Option
                                  key={option.employeeId}
                                  value={option.employeeId}
                                >
                                  {option.managerName}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col lg={5}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label style={{ fontWeight: "600" }}>
                            L2 Manager ID:
                          </label>
                          <Form.Item name="l2ManagerId">
                            <Select
                              listItemHeight={10}
                              listHeight={200}
                              showSearch
                              filterOption={filterOption}
                              placeholder="Select L2 Manager ID"
                              // style={{ width: 200 }}
                              virtual={true}
                            >
                              {managerId?.map((option) => (
                                <Select.Option
                                  key={option.employeeId}
                                  value={option.employeeId}
                                >
                                  {option.managerName}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col lg={5}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label style={{ fontWeight: "600" }}>
                            Business Unit:
                          </label>
                          <Form.Item name="businessUnit">
                            <Select
                              listItemHeight={10}
                              listHeight={200}
                              showSearch
                              filterOption={filterOption}
                              placeholder="Select Business Unit"
                              // style={{ width: 200 }}
                              virtual={true}
                              onChange={(value) => {
                                form.setFieldsValue({
                                  departmentId: "",
                                });
                                businessUnitChange(value);
                              }}
                            >
                              {businessUnit?.map((option) => (
                                <Select.Option
                                  key={option.businessUnit}
                                  value={option.businessUnit}
                                >
                                  {option.businessUnit}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col lg={5}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label style={{ fontWeight: "600" }}>
                            Department ID:
                          </label>
                          <Form.Item name="departmentId">
                            <Select
                              listItemHeight={10}
                              listHeight={200}
                              showSearch
                              filterOption={filterOption}
                              placeholder="Select Department ID"
                              // style={{ width: 200 }}
                              virtual={true}
                            >
                              {departmentId?.map((option) => (
                                <Select.Option key={option} value={option}>
                                  {option}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col lg={5}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label style={{ fontWeight: "600" }}>
                            Cost Center:
                          </label>
                          <Form.Item name="costCenter">
                            <Select
                              listItemHeight={10}
                              listHeight={200}
                              showSearch
                              filterOption={filterOption}
                              placeholder="Select Cost Center"
                              // style={{ width: 200 }}
                              virtual={true}
                            >
                              {costCenter?.map((option) => (
                                <Select.Option key={option} value={option}>
                                  {option}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
               
                    </Row>
                    <Row gutter={5}>
                    <Col lg={4}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label style={{ fontWeight: "600" }}>
                            User Type:
                          </label>
                          <Form.Item name="userType">
                            <Select placeholder="Select User Type">
                              <Select.Option key="cash" value="1">
                                People Manager
                              </Select.Option>
                              <Select.Option key="corporateCard" value="2">
                                Employee
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col lg={8}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label style={{ fontWeight: "600" }}>
                            Finance Contact Person:
                          </label>
                          <Form.Item name="financeContactPerson">
                            <Select
                              onChange={(value) =>
                                financeContactPersonChange(value)
                              }
                              listItemHeight={10}
                              listHeight={200}
                              showSearch
                              filterOption={filterOption}
                              placeholder="Select Finance Person"
                              // style={{ width: 200 }}
                              virtual={true}
                            >
                              {financePerson?.map((option) => (
                                <Select.Option
                                  key={option.employeeId}
                                  value={option.employeeId}
                                >
                                  {option.employeeName}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col lg={8}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label style={{ fontWeight: "600" }}>
                            Finance Contact Person Email:
                          </label>
                          <Form.Item name="financeContactPersonEmail">
                            <Input
                              disabled
                              placeholder="Enter Finance Person Email"
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row> */}
                    <div
                      style={{
                        fontSize: "18px",
                        fontWeight: "600",
                        marginTop: "0.1rem",
                      }}
                    >
                      <span className="font-bold">Personal Info</span>
                    </div>
                    <div
                      style={{
                        // marginTop: "1rem",
                        // height: "60vh",
                        overflowX: "hidden",
                        // overflowY: "auto",
                        // marginRight: "0.2rem",
                      }}
                    >
                      {/* first grid */}
                      <div className=" mb-0 mt-2 grid  grid-cols-4 gap-1 ">
                        <div className="pd-0 ">
                          <div>
                            <label style={{ fontWeight: "600" }}>
                              Organization:
                            </label>
                            <Input
                              disabled
                              value={sessionStorage.getItem("organization")}
                            />
                          </div>
                        </div>
                        <div>
                          <div>
                            <label style={{ fontWeight: "600" }}>
                              Admin ID:
                            </label>
                            <Input
                              disabled
                              value={sessionStorage.getItem("employeeId")}
                            />
                          </div>
                        </div>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label style={{ fontWeight: "600" }}>
                            Admin Email:
                          </label>
                          <Input
                            disabled
                            value={sessionStorage.getItem("emailId")}
                          />
                        </div>

                        <div>
                          <label style={{ fontWeight: "600" }}>
                            Employee ID:
                          </label>
                          <Form.Item name="employeeId">
                            <Input placeholder="Enter Employee Id" />
                          </Form.Item>
                        </div>
                        <div>
                          <div>
                            <label style={{ fontWeight: "600" }}>
                              Employee First Name:
                            </label>
                            <Form.Item name="employeeFirstName">
                              <Input placeholder="Enter Employee First Name" />
                            </Form.Item>
                          </div>
                        </div>
                        <div>
                          <label style={{ fontWeight: "600" }}>
                            Employee Middle Name:
                          </label>
                          <Form.Item name="employeeMiddleName">
                            <Input placeholder="Enter Employee Middle Name" />
                          </Form.Item>
                        </div>
                        <div>
                          <label style={{ fontWeight: "600" }}>
                            Employee Last Name:
                          </label>
                          <Form.Item name="employeeLastName">
                            <Input placeholder="Enter Employee Last Name" />
                          </Form.Item>
                        </div>

                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label style={{ fontWeight: "600" }}>Email ID:</label>
                          <Form.Item name="emailId">
                            <Input placeholder="Enter Employee Email ID" />
                          </Form.Item>
                        </div>
                      </div>
                      {/* grid end here */}
                      <div className="secondgird">
                        <div
                          style={{
                            fontSize: "18px",
                            fontWeight: "600",
                            // marginTop: "1rem",
                          }}
                        >
                          <span className="font-bold">Bank Details</span>
                        </div>
                        <div className=" mb-0 mt-2 grid  grid-cols-4 gap-1 ">
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label style={{ fontWeight: "600" }}>
                              Bank Name:
                            </label>
                            <Form.Item name="bankDetails">
                              <Input placeholder="Enter Bank Name" />
                            </Form.Item>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label style={{ fontWeight: "600" }}>
                              Bank Code Name:
                            </label>
                            <Form.Item name="bankCodeName">
                              <Select placeholder="Select Bank Code Name">
                                <Select.Option key="IFSC" value="IFSC">
                                  IFSC
                                </Select.Option>
                                <Select.Option key="MICR" value="MICR">
                                  MICR
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </div>

                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label style={{ fontWeight: "600" }}>
                              Bank Code Number:
                            </label>
                            <Form.Item name="ifscMicrOtherCode">
                              <Input placeholder="Enter Bank Code Number" />
                            </Form.Item>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label style={{ fontWeight: "600" }}>
                              Bank Acc. Number:
                            </label>
                            <Form.Item name="bankAccountNumber">
                              <Input placeholder="Enter Acc. Number" />
                            </Form.Item>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label style={{ fontWeight: "600" }}>
                              Employee Name in Bank Record:
                            </label>
                            <Form.Item name="employeeNameInBankRecord">
                              <Input placeholder="Enter Employee Name in Bank Record" />
                            </Form.Item>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label style={{ fontWeight: "600" }}>
                              Country Name:
                            </label>
                            <Form.Item name="employeeCountryName">
                              <Select
                                listItemHeight={10}
                                listHeight={200}
                                showSearch
                                onChange={(value) => countryNameChange(value)}
                                filterOption={filterOption}
                                placeholder="Select Country Name"
                                // style={{ width: 200 }}
                                virtual={true}
                              >
                                {countryName?.map((option) => (
                                  <Select.Option
                                    key={option.countryName}
                                    value={option.countryName}
                                  >
                                    {option.countryName}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label style={{ fontWeight: "600" }}>
                              Employee Currency Name:
                            </label>
                            <Form.Item name="employeeCurrencyName">
                              <Input
                                disabled
                                placeholder="Enter Employee Currency Name"
                              />
                            </Form.Item>
                          </div>

                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label style={{ fontWeight: "600" }}>
                              Employee Currency Code:
                            </label>
                            <Form.Item name="employeeCurrencyCode">
                              <Input
                                disabled
                                placeholder="Enter Employee Currency Code"
                              />
                            </Form.Item>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label style={{ fontWeight: "600" }}>
                              Date Of Joining:
                            </label>
                            <Form.Item name="dateOfJoining">
                              <DatePicker
                                className="inputclassexpenseadmin"
                                // style={{ width: "100%" }}
                                selected={dateOfJoining}
                                onChange={(date) => handleDateChange(date)}
                                // className="form-control"
                                placeholderText="Select Joining Date"
                                dateFormat="dd/MM/yyyy"
                              />
                            </Form.Item>
                          </div>

                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label style={{ fontWeight: "600" }}>
                              Employee Business Title:
                            </label>
                            <Form.Item name="employeeBusinessTitle">
                              <Input placeholder="Enter Employee Business Title" />
                            </Form.Item>
                          </div>
                        </div>
                        {/* 2 nd grid end here  */}
                      </div>

                      {/* third grid */}
                      <div className="secondgird">
                        <div
                          style={{
                            fontSize: "18px",
                            fontWeight: "600",
                            // marginTop: "1rem",
                          }}
                        >
                          <span className="font-bold">
                            Organization Information
                          </span>
                        </div>
                        <div className=" mb-0 mt-2 grid  grid-cols-4 gap-1 ">
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label style={{ fontWeight: "600" }}>
                              Manager Name:
                            </label>
                            <Form.Item name="managerId">
                              <Select
                                listItemHeight={10}
                                listHeight={200}
                                showSearch
                                onChange={(value) => {
                                  changeManagerName(value);
                                }}
                                filterOption={filterOption}
                                placeholder="Select Manager Name"
                                // style={{ width: 200 }}
                                virtual={true}
                              >
                                {managerId?.map((option) => (
                                  <Select.Option
                                    key={option.employeeId}
                                    value={option.employeeId}
                                  >
                                    {option.managerName}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label style={{ fontWeight: "600" }}>
                              Manager ID:
                            </label>
                            <Form.Item name="managerId">
                              <Input disabled />
                            </Form.Item>
                          </div>

                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label style={{ fontWeight: "600" }}>
                              L1 Manager ID:
                            </label>
                            <Form.Item name="l1ManagerId">
                              <Select
                                listItemHeight={10}
                                listHeight={200}
                                showSearch
                                filterOption={filterOption}
                                placeholder="Select L1 Manager ID"
                                // style={{ width: 164 }}
                                virtual={true}
                              >
                                {managerId?.map((option) => (
                                  <Select.Option
                                    key={option.employeeId}
                                    value={option.employeeId}
                                  >
                                    {option.managerName}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label style={{ fontWeight: "600" }}>
                              L2 Manager ID:
                            </label>
                            <Form.Item name="l2ManagerId">
                              <Select
                                listItemHeight={10}
                                listHeight={200}
                                showSearch
                                filterOption={filterOption}
                                placeholder="Select L2 Manager ID"
                                // style={{ width: 200 }}
                                virtual={true}
                              >
                                {managerId?.map((option) => (
                                  <Select.Option
                                    key={option.employeeId}
                                    value={option.employeeId}
                                  >
                                    {option.managerName}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label style={{ fontWeight: "600" }}>
                              Business Unit:
                            </label>
                            <Form.Item name="businessUnit">
                              <Select
                                listItemHeight={10}
                                listHeight={200}
                                showSearch
                                filterOption={filterOption}
                                placeholder="Select Business Unit"
                                // style={{ width: 200 }}
                                virtual={true}
                                onChange={(value) => {
                                  form.setFieldsValue({
                                    departmentId: "",
                                  });
                                  businessUnitChange(value);
                                }}
                              >
                                {businessUnit?.map((option) => (
                                  <Select.Option
                                    key={option.businessUnit}
                                    value={option.businessUnit}
                                  >
                                    {option.businessUnit}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label style={{ fontWeight: "600" }}>
                              Department ID:
                            </label>
                            <Form.Item name="departmentId">
                              <Select
                                listItemHeight={10}
                                listHeight={200}
                                showSearch
                                filterOption={filterOption}
                                placeholder="Select Department ID"
                                // style={{ width: 200 }}
                                virtual={true}
                              >
                                {departmentId?.map((option) => (
                                  <Select.Option key={option} value={option}>
                                    {option}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label style={{ fontWeight: "600" }}>
                              Cost Center:
                            </label>
                            <Form.Item name="costCenter">
                              <Select
                                listItemHeight={10}
                                listHeight={200}
                                showSearch
                                filterOption={filterOption}
                                placeholder="Select Cost Center"
                                // style={{ width: 200 }}
                                virtual={true}
                              >
                                {costCenter?.map((option) => (
                                  <Select.Option key={option} value={option}>
                                    {option}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>

                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label style={{ fontWeight: "600" }}>
                              User Type:
                            </label>
                            <Form.Item name="userType">
                              <Select placeholder="Select User Type">
                                <Select.Option key="cash" value="1">
                                  People Manager
                                </Select.Option>
                                <Select.Option key="corporateCard" value="2">
                                  Employee
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label style={{ fontWeight: "600" }}>
                              Finance Contact Person:
                            </label>
                            <Form.Item name="financeContactPerson">
                              <Select
                                onChange={(value) =>
                                  financeContactPersonChange(value)
                                }
                                listItemHeight={10}
                                listHeight={200}
                                showSearch
                                filterOption={filterOption}
                                placeholder="Select Finance Person"
                                // style={{ width: 200 }}
                                virtual={true}
                              >
                                {financePerson?.map((option) => (
                                  <Select.Option
                                    key={option.employeeId}
                                    value={option.employeeId}
                                  >
                                    {option.employeeName}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>

                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <label style={{ fontWeight: "600" }}>
                              Finance Contact Person Email:
                            </label>
                            <Form.Item name="financeContactPersonEmail">
                              <Input
                                disabled
                                placeholder="Enter Finance Person Email"
                              />
                            </Form.Item>
                          </div>
                        </div>
                        {/* 2 nd grid end here  */}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "2rem",
                    }}
                  >
                    <Row>
                      <Col>
                        <Button
                          style={{
                            width: "8.5rem",
                            backgroundColor: "#3052D0",
                            border: "none",
                            color: "white",
                          }}
                          htmlType="submit"
                        >
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </Spin>
            </Col>
          </Row>

          <Footer />
        </div>
      ) : (
        <PageNotFound />
      )}
    </>
  );
}

export default ExpenseAdminDashboard;
