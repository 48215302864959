import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datepicker.css";
import "./carrental.css";
import { carRental } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelRequest,
  clearRequest,
  postTransport,
} from "../services/ApiService";
import { useNavigate } from "react-router-dom";
import { runes } from "runes2";
import { decryptObject } from "./GlobalFunctions";

const { TextArea } = Input;
function formatDate(date) {
  if (date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  return "";
}
function CarRentals({ triggerParentEffect }) {
  const [restrictedFlag, setRestrictedFlag] = useState();
  const flags = useSelector((state) => state.flags);
  const requestid = useSelector((state) => state.requestedid);
  const [form] = Form.useForm();
  const userType = sessionStorage.getItem("userType");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const carRentalData = useSelector((state) => state.carRental);
  const travelHeader = useSelector((state) => state.travelHeader);
  const dispatch = useDispatch();
  const requestPolicy = useSelector(
    (state) => state.travelHeader.requestPolicy
  );

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setEndDate(null);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const today = new Date();
  const onFinish = (value) => {
    let formatStartDate = formatDate(startDate);
    let formatEndDate = formatDate(endDate);
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        value[key] = value[key] !== undefined ? value[key] : "";
      }
    }
    let obj = {
      requestId: requestid,
      employeeId: sessionStorage.getItem("employeeId"),
      transportType: "carRental",
      estimateCost: value.estimateCostCarRental,
      comment: value.comment1,
      startDate: formatStartDate,
      endDate: formatEndDate,
    };

    if (Object.values(value).every((field) => field !== "")) {
      postTransport("carRental", obj, requestPolicy).then((res) => {
        if (res.responseCode === 200) {
          message.success("Car Rental Data Saved Succesfully");
          triggerParentEffect(obj);
        } else {
          message.error(res.responseMessage);
        }
      });
      let reduxobj = {
        transportType: "carRental",
        estimateCost: value.estimateCostCarRental,
        comment: value.comment1,
        startDate: formatStartDate,
        endDate: formatEndDate,
      };
      dispatch(carRental(reduxobj));
    } else {
      message.error("All Fields are required");
    }
  };

  useEffect(() => {
    const flagsObject = decryptObject(flags);
    setRestrictedFlag(flagsObject);
    if (Object.keys(carRentalData).length > 0) {
      form.setFieldsValue({
        estimateCostCarRental: carRentalData.estimateCost,
        comment1: carRentalData.comment,
      });
      setStartDate(new Date(carRentalData.startDate));
      setEndDate(new Date(carRentalData.endDate));
    }
  }, []);
  const onCancel = () => {
    setOpen(true);
  };
  const onSubmit = () => {
    setOpen(false);
    let body = {
      requestId: requestid,
    };
    if (requestid) {
      cancelRequest(body).then((res) => {
        if (res.responseCode === 200) {
          message.success("Canceled the Request Successfully");

          if (userType == "1") {
            navigate("/dashboard-m");
          } else {
            navigate("/dashboard");
          }
        } else {
          message.error(res.responseMessage);
        }
      });
    } else {
      if (userType == "1") {
        navigate("/dashboard-m");
      } else {
        navigate("/dashboard");
      }
      message.error("Data is not Saved Yet");
    }
  };
  const onClear = () => {
    if (requestid) {
      form.resetFields();
      setStartDate(null);
      setEndDate(null);
      let body = {
        requestId: requestid,
        requestType: "transport",
        transportType: "carRental",
      };
      clearRequest(body).then((res) => {
        if (res.responseCode === 200) {
          dispatch(carRental({}));
          triggerParentEffect(body);
          message.success("Car Rental Data Cleared Successfully");
        } else {
          message.error(res.responseMessage);
        }
      });
    } else {
      message.error("Please Save Travel Overview Data");
    }
  };
  return (
    <Form form={form} onFinish={onFinish}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "3rem",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          <label style={{ color: "#2F3D4C", fontWeight: "600" }}>
            Start Date
          </label>
          <Form.Item
            rules={[{ required: true, message: "Please Enter Start Date" }]}
          >
            <DatePicker
              selected={startDate}
              onChange={handleStartDateChange}
              dateFormat="dd/MM/yyyy"
              // required
              minDate={new Date(travelHeader.startDate)} // Disable dates before today
              maxDate={new Date(travelHeader.endDate)}
              className="form-control"
              placeholderText="Select Start Date"
            />
          </Form.Item>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          <label style={{ color: "#2F3D4C", fontWeight: "600" }}>
            End Date
          </label>
          <Form.Item
            rules={[{ required: true, message: "Please Enter End Date" }]}
          >
            <DatePicker
              selected={endDate}
              onChange={handleEndDateChange}
              dateFormat="dd/MM/yyyy"
              // required
              minDate={startDate || new Date(travelHeader.startDate)} // Disable dates before start date or today
              maxDate={new Date(travelHeader.endDate)}
              className="form-control"
              placeholderText="Select End Date"
            />
          </Form.Item>
        </div>
        {restrictedFlag?.globalFlag?.estimatedCost === 1 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
          >
            <label style={{ color: "#2F3D4C", fontWeight: "600" }}>
              Estimate Cost for Car Rental
            </label>
            <Form.Item
              className="custom-form-item"
              name="estimateCostCarRental"
            >
              <InputNumber
                defaultValue={0}
                style={{ width: "100%" }}
                className="custom-radio-group"
                placeholder="Enter Amount"
              />
            </Form.Item>
          </div>
        )}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
        }}
      >
        <label style={{ color: "#2F3D4C", fontWeight: "600" }}>Comment</label>
        <Form.Item className="custom-form-item" name="comment1">
          <TextArea
            // count={{
            //   show: true,
            //   max: 1000,
            //   strategy: (txt) => runes(txt).length,
            //   exceedFormatter: (txt, { max }) =>
            //     runes(txt).slice(0, max).join(""),
            // }}
            style={{ resize: "none" }}
            className="custom-radio-group"
            rows={4}
            placeholder="Please describe the taxi cost details here"
          />
        </Form.Item>
        <div
          style={{ display: "flex", justifyContent: "center", gap: "1.5rem" }}
        >
          <Button
            style={{
              width: "8.5rem",
              backgroundColor: "#3052D0",
              border: "none",
              color: "white",
            }}
            htmlType="submit"

            //   onClick={handleSave}
          >
            Save
          </Button>
          <Button
            style={{
              width: "8.5rem",
              backgroundColor: "red",
              border: "none",
              color: "white",
            }}
            onClick={onClear}
          >
            Clear
          </Button>
          <Popconfirm
            title="Are you sure, want to Cancel the whole request"
            okText="Yes"
            cancelText="No"
            // description="Open Popconfirm with Promise"
            onConfirm={onSubmit}
            // onCancel={() => setOpen(false)}
          >
            <Button
              style={{
                width: "8.5rem",
                backgroundColor: "transparent",
                border: "1px solid red",
                color: "red",
              }}
            >
              Cancel
            </Button>
          </Popconfirm>
        </div>
      </div>
      {/* <Modal
        open={open}
        title="Are you sure, want to Cancel the whole request"
        onCancel={() => setOpen()}
        footer={[
          <Button key="submit" type="primary" onClick={onSubmit}>
            Yes
          </Button>,
          <Button onClick={() => setOpen(false)}>No</Button>,
        ]}
      ></Modal> */}
    </Form>
  );
}

export default CarRentals;
