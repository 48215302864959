import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, message } from "antd";
import Header from "./Header";
import Footer from "./Footer";

import {
  amount,
  bus,
  carRental,
  cashAdvance,
  claimno,
  flight,
  hoteldata,
  perdiem,
  perdiemextra,
  requesteid,
  taxi,
  train,
  travelAdvanceExpense,
  travelHeader,
} from "../redux/actions";
import { useDispatch } from "react-redux";
import ExpenseAdminSideBar from "./ExpenseAdminSideBar";
import {
  employeeIdExpenseAdmin,
  managerIdExpenseAdmin,
  onUpdateExpenseAdmin,
} from "../services/ApiService";
import PageNotFound from "./PageNotFound";

function Update() {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const [employeeId, setEmployeeId] = useState([]);
  const [managerId, setManagerId] = useState([]);
  const adminRights=sessionStorage.getItem('aSF')

  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(bus({}));
    dispatch(cashAdvance({}));
    dispatch(carRental({}));
    dispatch(flight({}));
    dispatch(hoteldata({}));
    dispatch(perdiem({}));
    dispatch(perdiemextra({}));
    dispatch(taxi({}));
    dispatch(train({}));
    dispatch(travelHeader({}));
    dispatch(requesteid(""));
    dispatch(claimno(""));
    dispatch(amount(0));
    dispatch(travelAdvanceExpense({}));


    const checkScreenSize = () => {
      setCollapsed(window.innerWidth < 768);
    };
    checkScreenSize();

    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);
  useEffect(() => {
    let header = {
      organization: sessionStorage.getItem("organization"),
      expenseAdmin: sessionStorage.getItem("employeeId"),
    };
    employeeIdExpenseAdmin(header).then((res) => {
      if (res.responseCode === 200) {
        setEmployeeId(res.data);
      } else {
        // message.error(res.responseMessage);
      }
    });

    managerIdExpenseAdmin(header).then((res) => {
      if (res.responseCode === 200) {
        setManagerId(res.data);
      } else {
        message.error(res.responseMessage);
      }
    });
  }, []);
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };
  const employeeIdChange = (value) => {
    const selectedCountryObject = employeeId.find(
      (item) => item.employeeId === value
    );

    form.setFieldsValue({
      employeeName: selectedCountryObject.employeeName,
      existingEmployeeBusinessTitle:selectedCountryObject.oldBusinessTitle
      
    });
  };
  const onFinish = (value) => {
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        value[key] = value[key] !== undefined ? value[key] : "";
      }
    }
    delete value["existingEmployeeBusinessTitle"]

    if (Object.values(value).every((field) => field !== '')) {
    
      onUpdateExpenseAdmin(value).then((res) => {
        if (res.responseCode === 200) {
          message.info("Successfully updated");
          form.resetFields();
        } else {
          message.error(res.responseMessage);
        }
      });
    } else {
      message.error("All Fields Required")
    }
  
  };
  const changeManagerName = (value) => {
    const selectedCountryObject = managerId.find(
      (item) => item.employeeId === value
    );
    form.setFieldsValue({
      managerId: selectedCountryObject.employeeId,
    });
  };
  return (
   <>
    {
      adminRights==='2' || adminRights === '3'?    <div style={{ height: "100vh", backgroundColor: "#F7F8FA" }}>
      <Row>
        <Col
          xs={!collapsed ? 19 : 11}
          sm={!collapsed ? 10 : 6}
          md={!collapsed ? 7 : 4}
          lg={!collapsed ? 4 : 5}
          xl={!collapsed ? 5 : 3}
        >
          <div
            style={{ position: "fixed", zIndex: "1" }}
            className={`toggle ${collapsed ? "collapsed" : ""}`}
            onClick={toggleSidebar}
          >
            <svg
              className={`arrow ${collapsed ? "collapsed" : ""}`}
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.39635 2.69038C9.19753 2.49709 8.8752 2.49709 8.67638 2.69038L4.60366 6.64997C4.50818 6.74279 4.45455 6.86869 4.45455 6.99996C4.45455 7.13122 4.50818 7.25712 4.60366 7.34994L8.67638 11.3095C8.8752 11.5028 9.19753 11.5028 9.39635 11.3095C9.59516 11.1162 9.59516 10.8029 9.39635 10.6096L5.6836 6.99996L9.39635 3.39034C9.59516 3.19705 9.59516 2.88367 9.39635 2.69038Z"
                fill="black"
              />
            </svg>
          </div>
          <ExpenseAdminSideBar collapsed={collapsed} id={"3"}/>
        </Col>

        <Col
          xs={!collapsed ? 5 : 13}
          sm={!collapsed ? 14 : 18}
          md={!collapsed ? 17 : 20}
          lg={!collapsed ? 20 : 19}
          xl={!collapsed ? 19 : 21}
        >
          <Header expense={false} travel={true} />
          <Form form={form} onFinish={onFinish} layout="horizontal">
          {/* <div style={{fontSize:'19px',fontWeight:'600',marginTop:'1rem'}}>
                Update
              </div> */}
            <div
              style={{
                marginTop: "2rem",
                height: "60vh",
                overflowX: "hidden",
                overflowY: "auto",
                marginRight: "1rem",
              }}
            >
              <Row gutter={6}>
                {/* <Col lg={6}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label style={{ fontWeight: "600" }}>Organization:</label>
                    <Input
                      disabled
                      value={sessionStorage.getItem("organization")}
                    />
                  </div>
                </Col> */}

                <Col lg={5}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label style={{ fontWeight: "600" }}>Employee ID:</label>
                    <Form.Item name="employeeId">
                      <Select
                        listItemHeight={10}
                        listHeight={200}
                        showSearch
                        onChange={(value) => {
                          employeeIdChange(value);
                        }}
                        filterOption={filterOption}
                        placeholder="Select Employee ID"
                        // style={{ width: 200 }}
                        virtual={true}
                      >
                        {employeeId?.map((option) => (
                          <Select.Option
                            key={option.employeeId}
                            value={option.employeeId}
                          >
                            {option.employeeId}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={7}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label style={{ fontWeight: "600" }}>Employee Name:</label>
                    <Form.Item name="employeeName">
                      <Input disabled placeholder="Enter Employee Name" />
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={6}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label style={{ fontWeight: "600" }}>
                      Existing Business Title:
                    </label>
                    <Form.Item name="existingEmployeeBusinessTitle">
                      <Input disabled placeholder="Employee Business Title" />
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={6}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label style={{ fontWeight: "600" }}>
                      New Business Title:
                    </label>
                    <Form.Item name="businessTitle">
                      <Input placeholder="Enter Employee Business Title" />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row gutter={6}>
                <Col lg={5}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label style={{ fontWeight: "600" }}>Manager Name:</label>
                    <Form.Item name="managerId">
                      <Select
                        listItemHeight={10}
                        listHeight={200}
                        showSearch
                        filterOption={filterOption}
                        onChange={(value)=>{changeManagerName(value)}}

                        placeholder="Select Manager Name"
                        // style={{ width: 200 }}
                        virtual={true}
                      >
                        {managerId?.map((option) => (
                          <Select.Option
                            key={option.employeeId}
                            value={option.employeeId}
                          >
                            {option.managerName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={5}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label style={{ fontWeight: "600" }}>Manager ID:</label>
                    <Form.Item name="managerId">
                    <Input
                              disabled
          
                            />
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={5}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label style={{ fontWeight: "600" }}>User Type:</label>
                    <Form.Item name="userType">
                      <Select placeholder="Select User Type">
                        <Select.Option key="1" value="1">
                          People manager
                        </Select.Option>
                        <Select.Option key="2" value="2">
                          Employee
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1.6rem",
              }}
            >
              <Row>
                <Col>
                  <Button
                    style={{
                      width: "8.5rem",
                      backgroundColor: "#3052D0",
                      border: "none",
                      color: "white",
                    }}
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Col>
      </Row>

      <Footer />
    </div>:<PageNotFound/>
    }
   </>

  );
}

export default Update;
