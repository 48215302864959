import {
  Button,
  Col,
  Form,
  Modal,
  Popconfirm,
  Row,
  Select,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { perdiem } from "../redux/actions";
import {
  cancelRequest,
  clearRequest,
  countryNameExpenseAdmin,
  exchangeRate,
  getDatesForPerDiem,
  getPerDiem,
  perDiemRequest,
} from "../services/ApiService";
import { useNavigate } from "react-router-dom";
import DateRowsComponent from "./DateRowsComponent";
import { MdCancel } from "react-icons/md";
import { FaCircle } from "react-icons/fa";

const initialFormData = {
  date: null,
  country: "",
  entitlement: "",
  currencyCode: "",
  exchangeRate: 0,
  dailyAllowance: "",
};
function PerDiem({ triggerParentEffect }) {
  const requestid = useSelector((state) => state.requestedid);
  const requestName = useSelector((state) => state.travelHeader.requestName);
  const dates = useSelector((state) => state.travelHeader.dates);
  const dispatch = useDispatch();
  const [array, setArray] = useState([]);
  const userType = sessionStorage.getItem("userType");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [perDiemAmount, setPerDiemAmount] = useState(0);
  const [countryOptions, setCountryOptions] = useState([]);
  const requestPolicy = useSelector(
    (state) => state.travelHeader.requestPolicy
  );
  const [date, setDate] = useState([]);
  // const date = ["2024-04-23", "2024-04-24", "2024-04-25"];

  const [formData, setFormData] = useState([{ ...initialFormData, id: 0 }]);
  const handleRemoveForm = (index) => {
    const newFormData = [...formData];
    newFormData.splice(index, 1);
    setFormData(newFormData);
  };
  const convertDate = (value) => {
    const date = new Date(value);
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");

    return `${dd}-${mm}-${yyyy}`;
  };

  useEffect(() => {
    countryNameExpenseAdmin().then((res) => {
      if (res.responseCode === 200) {
        setCountryOptions(res.data);
      }
    });

    if (requestid) {
      getPerDiem(requestid, "travel", requestPolicy).then((res) => {
        if (res.responseCode === 200) {
          if (res.data) {
            if (res.data.length > 0) {
              const initialFormDataArray = res.data.map((obj) => ({
                date: convertDate(obj.diemDate),
                country: obj.country || "",
                currencyCode: obj.currencyCode || "",
                dailyAllowance: obj.dailyAllowance || "",
                entitlement: obj.entitlement || "",
                exchangeRate: obj.exchangeRate || "",
              }));
              setFormData(initialFormDataArray);
            } else {
              getDatesForPerDiem(requestid).then((res) => {
                if (res.responseCode === 200) {
                  setDate(res.dateRange);
                  const updatedFormData = res.dateRange.map((date, index) => ({
                    date,
                    country: "",
                    entitlement: "",
                    currencyCode: "",
                    exchangeRate: "",
                    dailyAllowance: "",
                  }));
                  setFormData(updatedFormData);
                }
              });
            }
          }
        } else {
          message.error(res.responseMessage);
        }
      });
    } else {
      message.info("Please Save Travel Overview");
    }
  }, []);
  function checkArray(array) {
    for (const obj of array) {
      for (const key in obj) {
        if (!obj[key]) {
          message.error("All Fields are required");
          return;
        }
      }
    }
    let body = {
      requestId: requestid,
      diems: formData,
    };

    perDiemRequest(body, requestPolicy).then((res) => {
      if (res.responseCode === 200) {
        triggerParentEffect(body);
        message.success("Saved");
      } else {
        message.error(res.responseMessage);
      }
    });
  }
  const onFinish = () => {
    checkArray(formData);
  };

  const handleCheckboxChange = (event, rowIndex, propertyName) => {
    const updatedData = [...array];
    updatedData[rowIndex][propertyName] = event.target.checked ? 1 : 0;
    setArray(updatedData);
  };
  const onCancel = () => {
    setOpen(true);
  };
  const onSubmit = () => {
    setOpen(false);
    let body = {
      requestId: requestid,
    };
    if (requestid) {
      cancelRequest(body).then((res) => {
        if (res.responseCode === 200) {
          message.success("Canceled the Request Successfully");

          if (userType == "1") {
            navigate("/dashboard-m");
          } else {
            navigate("/dashboard");
          }
        } else {
          message.error(res.responseMessage);
        }
      });
    } else {
      if (userType == "1") {
        navigate("/dashboard-m");
      } else {
        navigate("/dashboard");
      }
      message.error("Data is not Saved Yet");
    }
  };
  const onClear = () => {
    if (requestid) {
      const updatedData = array.map((item) => ({
        ...item,
        breakfast: 0,
        lunch: 0,
        dinner: 0,
      }));
      setArray(updatedData);
      let body = {
        requestId: requestid,
        requestType: "perdiem",
      };
      clearRequest(body).then((res) => {
        if (res.responseCode === 200) {
          dispatch(perdiem({}));
          triggerParentEffect(body);

          message.success("Data Cleared Successfully");
        } else {
          message.error(res.responseMessage);
        }
      });
    } else {
      message.info("Please Save Travel Overview Data");
    }
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  const filterOption = (input, option) => {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };
  const handleInputChange = (index, field, value) => {
    if (field === "country") {
      const selectedCountryObject = countryOptions.find(
        (item) => item.countryName === value
      );
      const newFormData = [...formData];
      newFormData[index]["entitlement"] = selectedCountryObject.entitlement;
      newFormData[index]["currencyCode"] = selectedCountryObject.currencyCode;
      newFormData[index]["exchangeRate"] = selectedCountryObject.exchangeRate;

      setFormData(newFormData);

      if (
        newFormData[index]["entitlement"] &&
        newFormData[index]["exchangeRate"]
      ) {
        newFormData[index]["dailyAllowance"] =
          newFormData[index]["entitlement"] *
          newFormData[index]["exchangeRate"];
      }
    } else if (field === "exchangeRate") {
      const newFormData = [...formData];
      newFormData[index][field] = value;
      newFormData[index]["dailyAllowance"] =
        newFormData[index]["entitlement"] *
        parseInt(newFormData[index]["exchangeRate"]);

      if (
        newFormData[index]["entitlement"] &&
        newFormData[index]["exchangeRate"]
      ) {
        newFormData[index]["dailyAllowance"] =
          newFormData[index]["entitlement"] *
          newFormData[index]["exchangeRate"];
      }
    }
    const newFormData = [...formData];
    newFormData[index][field] = value;
    setFormData(newFormData);
  };
  useEffect(() => {
    const totalDailyAllowance = formData.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.dailyAllowance;
    }, 0);
    setPerDiemAmount(totalDailyAllowance);
  }, [formData]);
  return (
    <div
      style={{
        backgroundColor: "white",
        margin: "1.5rem 1.5rem 0 0",
        display: "flex",
        justifyContent: "flex-start",
        borderRadius: "5px",
        padding: "1rem 1rem 1rem 2rem",
        flexDirection: "column",
      }}
    >
      <form>
        <div
          style={{
            height: "53vh",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between", // Ensures content is spaced evenly
              alignItems: "center", // Align items vertically
              marginBottom: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <span style={{ fontWeight: "600", marginRight: "0.5rem" }}>
                Request ID :
              </span>
              <span style={{ color: "#3052D0", fontWeight: "500" }}>
                {requestid ? requestid : ""}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  fontWeight: "600",
                  marginRight: "0.5rem",
                  marginLeft: "2rem",
                }}
              >
                Request Name :
              </span>
              <span style={{ color: "#3052D0", fontWeight: "500" }}>
                {requestName ? requestName : ""}
              </span>
            </div>
            <div
              style={{
                marginLeft: "auto", // Pushes this div to the rightmost area
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginRight: "3.5rem",
              }}
            >
              <span style={{ fontWeight: "600", marginRight: "0.5rem" }}>
                Per Diem Amount:
              </span>
              <span style={{ color: "#3052D0", fontWeight: "500" }}>
                {perDiemAmount ? perDiemAmount : 0}
                {/* {additionalInfo ? additionalInfo : ""} */}
              </span>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: "0.3rem" }}>
            <span style={{ fontWeight: "600" }}>Per Diem</span>
            <span style={{ fontSize: "12px", color: "#7B809A" }}>
              (*check mark only those box where it was paid by Company)
            </span>
          </div>
          <div style={{ marginTop: "0.5rem" }}>
            {formData.length >= 1 && (
              <Row style={{ marginBottom: "0.3rem" }}>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "700",
                  }}
                  lg={4}
                >
                  Date
                </Col>
                <Col
                  style={{
                    fontWeight: "700",
                  }}
                  lg={4}
                >
                  Country
                </Col>
                <Col
                  style={{
                    //  marginLeft:"1.5rem",
                    //  marginRight:"0.9rem",
                    fontWeight: "700",
                  }}
                  lg={3}
                >
                  Entitlement
                </Col>
                <Col
                  style={{
                    fontWeight: "700",
                  }}
                  lg={4}
                >
                  Currency
                </Col>
                <Col
                  style={{
                    fontWeight: "700",
                  }}
                  lg={4}
                >
                  Exchange Rate
                </Col>
                <Col
                  style={{
                    fontWeight: "700",
                  }}
                  lg={4}
                >
                  Daily Allowance
                </Col>
                <Col
                  style={{
                    fontWeight: "700",
                  }}
                  lg={1}
                >
                  {/* Currency */}
                </Col>
              </Row>
            )}
            <div style={{ height: "38vh", overflow: "auto" }}>
              {formData.length >= 1 &&
                formData?.map((form, index) => (
                  <Row style={{ marginBottom: "0", paddingBottom: "0" }}>
                    <Col
                      style={{ display: "flex", justifyContent: "center" }}
                      lg={4}
                    >
                      <FaCircle
                        style={{
                          color: "#3052D0",
                          fontSize: "8px",
                          marginTop: "0.7rem",
                          marginRight: "0.6rem",
                        }}
                      />
                      <input
                        disabled
                        value={convertDate(form.date)}
                        style={{ width: "68%" }}
                        className="inputclass"
                      />
                    </Col>
                    <Col
                      style={{
                        marginBottom: "0.4rem",
                      }}
                      lg={4}
                    >
                      <Select
                        value={form.country}
                        listItemHeight={10}
                        listHeight={200}
                        showSearch
                        onChange={(value) =>
                          handleInputChange(index, "country", value)
                        }
                        onSearch={onSearch}
                        filterOption={filterOption}
                        placeholder="Select Country"
                        style={{ width: 150 }}
                        virtual={true}
                      >
                        {countryOptions?.map((option) => (
                          <Select.Option
                            key={option.countryName}
                            value={option.countryName}
                          >
                            {option.countryName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                    <Col style={{}} lg={3}>
                      <input
                        disabled
                        value={form.entitlement}
                        style={{ width: "75%" }}
                        className="inputclass"
                        type="text"
                        placeholder="Entitlement"
                      />
                    </Col>
                    <Col lg={4} style={{}}>
                      <input
                        value={form.currencyCode}
                        disabled
                        style={{ width: "75%" }}
                        className="inputclass"
                        type="text"
                        placeholder="Currency"
                      />
                    </Col>
                    <Col lg={4} style={{}}>
                      <input
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "exchangeRate",
                            e.target.value
                          )
                        }
                        value={form.exchangeRate}
                        style={{ width: "80%" }}
                        className="inputclass"
                        type="number"
                        placeholder="Exchange Rate"
                      />
                    </Col>
                    <Col lg={4} style={{}}>
                      <input
                        disabled
                        value={form.dailyAllowance}
                        style={{ width: "75%" }}
                        className="inputclass"
                        type="text"
                        placeholder="Daily Allowance"
                      />
                    </Col>
                    <Col lg={1} style={{}}>
                      <MdCancel
                        onClick={() => handleRemoveForm(index)}
                        style={{
                          color: "red",
                          fontSize: "18px",
                          marginTop: "0.5rem",
                          cursor: "pointer",
                        }}
                      />
                    </Col>
                  </Row>
                ))}
            </div>
          </div>
        </div>

        <div
          style={{ display: "flex", justifyContent: "center", gap: "1.5rem" }}
        >
          <Button
            style={{
              width: "8.5rem",
              backgroundColor: "#3052D0",
              border: "none",
              color: "white",
            }}
            onClick={() => {
              onFinish();
            }}
          >
            Save
          </Button>
          <Button
            style={{
              width: "8.5rem",
              backgroundColor: "red",
              border: "none",
              color: "white",
            }}
            onClick={() => onClear()}
          >
            Clear
          </Button>
          <Popconfirm
            title="Are you sure, want to Cancel the whole request"
            okText="Yes"
            cancelText="No"
            // description="Open Popconfirm with Promise"
            onConfirm={onSubmit}
            // onCancel={() => setOpen(false)}
          >
            <Button
              style={{
                width: "8.5rem",
                backgroundColor: "transparent",
                border: "1px solid red",
                color: "red",
              }}
            >
              Cancel
            </Button>
          </Popconfirm>
        </div>
      </form>
    </div>
  );
}

export default PerDiem;
