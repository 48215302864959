import {
  Button,
  Col,
  Input,
  Modal,
  Row,
  Spin,
  Table,
  message,
  Menu,
} from "antd";
import React, { useEffect, useState } from "react";
import SideBar from "../SideBar";
import Header from "../Header";
import Footer from "../Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { GrStatusInfo } from "react-icons/gr";
import { FaCircle } from "react-icons/fa";
import "../travel.css";
import "./detailexpense.css";
import {
  PreviewFile,
  approval,
  approvalExpense,
  claimApprove,
  expensegethotel,
  getApprovedetails,
  getCashInAdvance,
  getClaimDetailsForTravelOverview,
  getCreditCard,
  getExpenseApprovedetails,
  getExpenseCashInAdvance,
  getExpensePerDiem,
  getExpenseTransport,
  getExpenseTravelRequest,
  getHotelData,
  getTransport,
  sendBackByManager,
  sendBackClaim,
  submitReceiptGet,
  submitReceiptList,
} from "../../services/ApiService";
import { runes } from "runes2";
import "../Expense/SubmitReceipt/mainpage.css";
import { DownloadOutlined } from "@ant-design/icons";
import { decryptObject } from "../GlobalFunctions";
import { useSelector } from "react-redux";
const { TextArea } = Input;

function DetailsExpense() {
  const flags = useSelector((state) => state.flags);
  const [restrictedFlag, setRestrictedFlag] = useState();
  const { state } = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [data, setData] = useState();
  const [details, setDetails] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [comment, setComment] = useState();
  const [claimDetails, setClaimDetails] = useState();
  const [spinner, setSpinner] = useState(false);
  const [total, setTotal] = useState();
  const [cashExpense, setCashExpense] = useState();
  const [corpExpense, setCorpExpense] = useState();
  const [currency, setCurrency] = useState();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [submitstatus, setSubmitstatus] = useState();
  const [previewlink, setPreviewLink] = useState();
  const [billFileName, setBillFileName] = useState();
  const [openpreview, setOpenpreview] = useState(false);
  const [modalFileType, setModalFileType] = useState();
  const [modal, setModal] = useState(false);
  const[advanceNow,setAdvanceNow]= useState()
  const[advanceRequested,setAdvanceRequested]= useState()



  const previewStyle = {
    maxWidth: "100%",
    maxHeight: "400px",
    margin: "20px 0",
  };
  const status =
    state.status === "submitted"
      ? "approved"
      : state.status === "approved"
      ? "sent for payment"
      : "paid";
  const urlLinkPart =
    state.status === "submitted"
      ? "manager-approve"
      : state.status === "approved"
      ? "admin-approve"
      : "finance-approve";
  const urlLinkPartForClaim =
    state.status === "submitted"
      ? "approved"
      : state.status === "approved"
      ? "sendforpayment"
      : "paid";
  const navigate = useNavigate();
  function camelToNormal(text) {
    return text.replace(/([a-z])([A-Z])/g, "$1 $2").toUpperCase();
  }
  const convertDateFormat = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}-${month}-${year}`;
  };
  const [selectedItem, setSelectedItem] = useState();
  const [downloadLink, setDownloadLink] = useState();
  const convertDatesInArray = (dataArray) => {
    return dataArray.map((item) => {
      const convertedItem = { ...item };
      if (item.startDate) {
        convertedItem.startDate = convertDateFormat(item.startDate);
      }
      if (item.endDate) {
        convertedItem.endDate = convertDateFormat(item.endDate);
      }
      if (item.departureDate) {
        convertedItem.departureDate = convertDateFormat(item.departureDate);
      }
      if (item.billDate) {
        convertedItem.billDate = convertDateFormat(item.billDate);
      }
      if (item.date) {
        convertedItem.date = convertDateFormat(item.date);
      }
      if (item.claimCreatedDate) {
        convertedItem.claimCreatedDate = convertDateFormat(
          item.claimCreatedDate
        );
      }
      if (item.checkIn) {
        convertedItem.checkIn = convertDateFormat(item.checkIn);
      }
      if (item.checkOut) {
        convertedItem.checkOut = convertDateFormat(item.checkOut);
      }
      if (item.returnDate) {
        convertedItem.returnDate = convertDateFormat(item.returnDate);
      }
      if (item.diemDate) {
        convertedItem.diemDate = convertDateFormat(item.diemDate);
      }
      return convertedItem;
    });
  };
  const removeRequestIdFromArray = (dataArray) => {
    return dataArray.map(({ requestId, ...rest }) => rest);
  };
  const [modalWidth, setModalWidth] = useState("80vw");
  const [modalHeight, setModalHeight] = useState("100vh");
const[requestPolicy,setRequestPolicy]=useState()
  useEffect(() => {
    const flagsObject = decryptObject(flags);
    setRestrictedFlag(flagsObject);
    const handleResize = () => {
      const width = window.innerWidth * 0.8; // 80% of the window width
      const height = window.innerHeight * 0.8; // 80% of the window height
      setModalWidth(`${width}px`);
      setModalHeight(`${height}px`);
    };

    // Set initial dimensions
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleDownload = () => {
    if (downloadLink) {
      window.open(downloadLink, "_blank");
    }
  };
  function getFileExtension(url) {
    const path = url.substring(url.lastIndexOf("/") + 1);
    const extension = path.substring(path.lastIndexOf(".") + 1);
    return extension;
  }
  const DynamicContentModal = ({
    visible,
    onCancel,
    dataArray,
    selectedItem,
  }) => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const calculateModalWidth = () => {
      const multiplier = 300;
      const maxWidth = 1000;
      const totalKeyValuePairs = dataArray.reduce(
        (total, obj) => total + Object.keys(obj).length,
        0
      );
      return Math.min(totalKeyValuePairs * multiplier, maxWidth);
    };

    const handlePreview = (
      billFile,
      billFileOriginalName,
      fileName,
      originalFileName
    ) => {
      setModal(false);
      setOpenpreview(true);
      if (state.id.charAt(0) === "C") {
        setBillFileName(originalFileName);
        PreviewFile(fileName).then((res) => {
          // if (res.responseCode === 200) {
setModalFileType(getFileExtension(res.url))
          setPreviewLink(res.url);
          setDownloadLink(res.downloadUrl);
          // window.open(res.fileUrl, "_blank");
          // }
        });
      } else {
        setBillFileName(billFileOriginalName);

        PreviewFile(billFile).then((res) => {
          // if (res.responseCode === 200) {
            setModalFileType(getFileExtension(res.url))

          setPreviewLink(res.url);
          setDownloadLink(res.downloadUrl);

          // window.open(res.fileUrl, "_blank");
          // }
        });
      }
    };

    const selectedData = dataArray[selectedTabIndex];

    const handleMenuClick = (e) => {
      setSelectedTabIndex(parseInt(e.key, 10));
    };
    return (
      <Modal
        open={visible}
        onCancel={onCancel}
        footer={null}
        width={modalWidth}
        height={modalHeight}
        // width={calculateModalWidth()}
        centered
        bodyStyle={{
          overflowY: "hidden",
          maxHeight: "80vh",
          overflowX: "hidden",
        }}
      >
        <div>
          {dataArray.length >= 2 && (
            <div>
              <div style={{ marginBottom: "1rem" }} className="menu-container">
                {/* Apply custom styles to Menu component to enable horizontal scrolling */}
                <Menu
                  style={{
                    width: "fit-content",
                    minWidth: "100%",
                    borderRadius: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // overflow:"auto"
                  }}
                  // mode="horizontal" // Set mode to horizontal
                  // style={{ whiteSpace: 'nowrap' }} // Prevent menu items from wrapping
                  selectedKeys={[selectedTabIndex.toString()]}
                  onClick={handleMenuClick}
                >
                  {dataArray.map((item, index) => (
                    <Menu.Item
                      style={{ minWidth: "auto" }}
                      key={index}
                    >{`${selectedItem} ${index + 1}`}</Menu.Item>
                  ))}
                </Menu>
              </div>
              {/* <Menu
              style={{
                width: "100%",
                borderRadius: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                
              }}
        
              selectedKeys={[selectedTabIndex.toString()]}
              // defaultSelectedKeys={["travelHeader"]}
              onClick={handleMenuClick}
            >
                {dataArray.map((item, index) => (
                  <Menu.Item key={index}>{`${selectedItem} ${index + 1}`}</Menu.Item>
                ))}
              </Menu> */}
            </div>
          )}

          <div
            style={{
              fontSize: "20px",
              fontWeight: "800",
              color: "#344767",
              marginBottom: "1rem",
            }}
          >
            Detailed Information:
          </div>

          <div
            style={{
              height: "45vh",
              borderRadius: "10px",
              overflowX: "hidden",
            }}
            className="scrollable-container"
          >
            <Row gutter={[16, 16]}>
              {selectedData &&
                Object.entries(selectedData).map(([key, value]) => {
                  if (
                    key === "selfDeclaration" &&
                    value?.toLowerCase() === "no"
                  ) {
                    return null;
                  }
                  if (
                    [
                      "billFile",
                      "billFileOriginalName",
                      "fileName",
                      "originalFileName",
                    ].includes(key)
                  ) {
                    return null;
                  }
                  return (
                    <Col span={8} key={key}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontWeight: "700",
                            color: "#344767",
                            fontSize: "14px",
                          }}
                        >
                          {camelToNormal(key)}
                        </span>
                        <span>{value}</span>
                      </div>
                    </Col>
                  );
                })}
            </Row>
          </div>

          {selectedData.billFile ||
          (selectedData.fileName && selectedData.fileName !== "null") ||
          selectedData.originalFileName ? (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "1rem",
              }}
            >
              <Button
                type="primary"
                style={{ backgroundColor: "#7A73FF" }}
                onClick={() =>
                  handlePreview(
                    selectedData.billFile,
                    selectedData.billFileOriginalName,
                    selectedData.fileName,
                    selectedData.originalFileName
                  )
                }
              >
                Preview Receipt
              </Button>
            </div>
          ) : null}
        </div>
      </Modal>
    );
  };
  useEffect(() => {
    if (state.id.charAt(0) == "C") {
      submitReceiptList(state.id).then((res) => {
        if (res.responseCode === 200) {
          setCashExpense(res.cashTotal);
          setCorpExpense(res.corporateTotal);
          setClaimDetails(res.data);
          setTotal(res?.totalCost);
          setCurrency(res?.currency);
          setAdvanceNow(res?.travelAdvance)
          setAdvanceRequested(res?.advanceRequested)
        } else {
          message.error(res.responseMessage);
        }
      });
    } else {
      getExpenseApprovedetails(state.id).then((res) => {
        if (res.responseCode === 200) {
          setData(res);
          setTotal(res?.totalCost);
          setCurrency(res?.currency);
          setDetails(res?.data);
          setRequestPolicy(res?.requestPolicy)
          console.log("res",res)
        }
      });
    }
  }, []);
  useEffect(() => {
    const checkScreenSize = () => {
      setCollapsed(window.innerWidth < 768);
    };
    checkScreenSize();

    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  const handleIconClick = (itemName) => {
    setSelectedItem(itemName.expenseType);
    if (itemName.expenseType === "Travel Advance") {
      getExpenseCashInAdvance(data.requestId).then((res) => {
        if (res.responseCode === 200) {
          let arr=[res?.data]

          // let obj = {
          //   "Cash In Advance Amount": res.data.cashInAdvance,
          //   "Cash Advance Reason": res.data.reasonCashInAdvance,
          // };
          // let arr = [];
          // arr.push(obj);
          setModalData(arr);
          setModalOpen(true);
          setModal(true);
        } else {
          message.error(res.responseMessage);
        }
      });
      // getCashInAdvance(data.requestId, "travel", state.requestPolicy).then(
      //   (res) => {
      //     if (res.responseCode === 200) {
      //       let arr=[res?.data]

      //       // let obj = {
      //       //   "Cash In Advance Amount": res.data.cashInAdvance,
      //       //   "Cash Advance Reason": res.data.reasonCashInAdvance,
      //       // };
      //       // let arr = [];
      //       // arr.push(obj);
      //       setModalData(arr);
      //       setModalOpen(true);
      //       setModal(true);
      //     } else {
      //       message.error(res.responseMessage);
      //     }
      //   }
      // );
    } 
   else if (itemName.expenseType === "Travel Overview") {
      getExpenseTravelRequest(data.requestId).then((res) => {
        if (res.responseCode === 200) {
          let arr=[res?.responseData]     
          const convertedArray = convertDatesInArray(
            arr
          );
          setModalData(convertedArray);

          setModalOpen(true);
          setModal(true);
        } else {
          message.error(res.responseMessage);
        }
      });
    } 
    else if (itemName.expenseType === "Hotel Fare") {
      expensegethotel(data.requestId).then((res) => {
        if (res.responseCode === 200) {
          if (res.data.length > 0) {
            const updatedFormData = res.data.map((obj) => {
              // Rename keys using object destructuring
              const {
                startDate: checkIn,
                endDate: checkOut,
                estimatedCost: totalCost,
                ...rest
              } = obj;

              // Calculate total nights between checkIn and checkOut dates
              const checkInDate = new Date(checkIn);
              const checkOutDate = new Date(checkOut);
              const timeDifference =
                checkOutDate.getTime() - checkInDate.getTime();
              const totalNights = Math.ceil(
                timeDifference / (1000 * 3600 * 24)
              );
              // Calculate total nights
              let estimatesDailyCost;
              if (totalNights > 0) {
                estimatesDailyCost = totalCost / totalNights;
              } else {
                estimatesDailyCost = 0;
              }
              // Create a new object with renamed keys, totalNights, and other properties
              const updatedObj = {
                checkIn,
                checkOut,
                totalNights,
                totalCost,
                estimatesDailyCost,
                ...rest, // Spread remaining properties from the original object
              };

              return updatedObj;
            });
            setModalOpen(true);
            setModal(true);
            const convertedArray = convertDatesInArray(updatedFormData);
            const newArrayWithoutRequestId =
              removeRequestIdFromArray(convertedArray);
            setModalData(newArrayWithoutRequestId);
          } else {
            message.info("No Data Found");
          }
        } else {
          message.error(res.responseMessage);
        }
      });
    } else if (itemName.expenseType === "Air Fare") {
      getExpenseTransport(data.requestId).then((res) => {
        if (res.responseCode === 200) {
          let filterDataflight = res.data?.filter(
            (item) => item.transportType === "flight"
          );
          if (filterDataflight.length > 0) {
            setModalOpen(true);
            setModal(true);
            const convertedArray = convertDatesInArray(
              filterDataflight[0].trips
            );
            setModalData(convertedArray);
          } else {
            message.info("No Data Found");
          }
        } else {
          message.error(res.responseMessage);
        }
      });
    } else if (itemName.expenseType === "Bus Fare") {
      getExpenseTransport(data.requestId).then((res) => {
        if (res.responseCode === 200) {
          let busData = res.data?.filter(
            (item) => item.transportType === "bus"
          );
          if (busData.length > 0) {
            setModalOpen(true);
            setModal(true);
            const convertedArray = convertDatesInArray(busData[0].trips);
            setModalData(convertedArray);
          } else {
            message.info("No Data Found");
          }
        } else {
          message.error(res.responseMessage);
        }
      });
    } else if (itemName.expenseType === "Train Fare") {
      getExpenseTransport(data.requestId).then((res) => {
        if (res.responseCode === 200) {
          let trainData = res.data?.filter(
            (item) => item.transportType === "train"
          );
          if (trainData.length > 0) {
            setModalOpen(true);
            setModal(true);
            const convertedArray = convertDatesInArray(trainData[0].trips);
            setModalData(convertedArray);
          } else {
            message.info("No Data Found");
          }
        } else {
          message.error(res.responseMessage);
        }
      });
    } else if (itemName.expenseType === "Car Rental") {
      getExpenseTransport(data.requestId).then((res) => {
        if (res.responseCode === 200) {
          let carRentalData = res.data?.filter(
            (item) => item.transportType === "carRental"
          );
          if (carRentalData.length > 0) {
            setModalOpen(true);
            setModal(true);
            delete carRentalData[0].transportType;
            const convertedArray = convertDatesInArray(carRentalData);
            setModalData(convertedArray);
          } else {
            message.info("No Data Found");
          }
        } else {
          message.error(res.responseMessage);
        }
      });
    } else if (itemName.expenseType === "Taxi Fare") {
      getExpenseTransport(data.requestId).then((res) => {
        if (res.responseCode === 200) {
          let taxiData = res.data?.filter(
            (item) => item.transportType === "taxi"
          );
          if (taxiData.length > 0) {
            setModalOpen(true);
            setModal(true);
            delete taxiData[0].transportType;
            const convertedArray = convertDatesInArray(taxiData[0].trips);

            setModalData(convertedArray);
          } else {
            message.info("No Data Found");
          }
        } else {
          message.error(res.responseMessage);
        }
      });
    }else if (itemName.expenseType === "Expense Perdiem") {
      getExpensePerDiem(data.requestId,requestPolicy).then((res) => {
        if (res.responseCode === 200) {
        
          if (res.data.length > 0) {
            setModalOpen(true);
            setModal(true);
            const convertedArray = convertDatesInArray(
              res.data
            );
            setModalData(convertedArray);
          } else {
            message.info("No Data Found");
          }
        } else {
          message.error(res.responseMessage);
        }
      });
    } 
  };
  const convertSelfDeclarationInArray = (data) => {
    return data.map((item) => {
      // Assuming 'selfDeclaration' is the key holding the value (0 or 1)
      // Perform conversion based on the selfDeclaration value
      const selfDeclarationText = item.selfDeclaration === 1 ? "yes" : "no";

      // Return a new object with updated selfDeclaration
      return {
        ...item,
        selfDeclaration: selfDeclarationText,
      };
    });
  };
  const handleIconClickForClaim = (itemName) => {
    if (
      itemName.category === "Credit Card-Import Corporate Credit Card Statement"
    ) {
      let obj = {
        claimNumber: state.id,
      };
      getCreditCard(obj).then((res) => {
        setSelectedItem("Credit Card");
        if (res.responseCode === 200) {
          if (res.data.length > 0) {
            setModalOpen(true);
            setModal(true);
            const convertedArray = convertDatesInArray(res.data);
            const newArrayWithoutRequestId =
              removeRequestIdFromArray(convertedArray);
            const selfDeclarationmanipulated = convertSelfDeclarationInArray(
              newArrayWithoutRequestId
            );
            setModalData(selfDeclarationmanipulated);
          } else {
            message.info("No Data Found");
          }
        } else {
          message.error(res.responseMessage);
        }
      });
    }
    else if (itemName.category === "Travel Overview") {
      getClaimDetailsForTravelOverview(state.id).then((res) => {
        if (res.responseCode === 200) {
          console.log(res)
          let arr = [res.travelOverview];
      const convertedArray = convertDatesInArray(arr);
          setModalData(convertedArray);
          setModalOpen(true);
          setModal(true)
        } else {
          message.error(res.responseMessage);
        }
      });
    } 
    else {
      submitReceiptGet(itemName.id).then((res) => {
        if (res.responseCode === 200) {
          delete res.data.id;
          delete res.data.requestedCurrency;
          res.data.selfDeclaration === 0
            ? (res.data.selfDeclaration = "No")
            : (res.data.selfDeclaration = "Yes");
          if (res.data.remark === "undefined") {
            delete res.data.remark;
          }
          if (res.data.teamName) {
            res.data.teamName = res.data.teamName.join(", ");
          }
          if (res.data.perEmployeeCost === null) {
            delete res.data.perEmployeeCost;
          }
          setModalOpen(true);
          setModal(true);
          let arr = [];
          arr.push(res.data);
          const convertedArray = convertDatesInArray(arr);
          const newArrayWithoutRequestId =
            removeRequestIdFromArray(convertedArray);
          setModalData(newArrayWithoutRequestId);
        } else {
          message.error(res.responseMessage);
        }
      });
    }
  };
  const handleModalCancel = () => {
    setModalOpen(false);
    setModal(false);
  };
  const toApprove = () => {
    setSubmitstatus("Approving...");
    setSpinner(true);
    if (state.id.charAt(0) === "C") {
      let obj = {
        claimNumber: state.id,
        // status: status,
        comment: comment,
      };
      // claimApprove(obj, urlLinkPartForClaim).then((res) => {
      claimApprove(obj).then((res) => {

        if (res.responseCode === 200) {
          setSpinner(false);
          message.success(res.responseMessage);
          navigate("/tobeapproveexpense");
        } else {
          setSpinner(false);
          message.error(res.responseMessage);
        }
      });
    } else {
      if (data.requestId) {
        let obj = {
          requestId: data ? data.requestId : "",
          status: status,
          comment: comment,
        };
        // approvalExpense(obj, urlLinkPart).then((res) => {
        approvalExpense(obj).then((res) => {

          if (res.responseCode === 200) {
            setSpinner(false);
            message.success("Approved Successfully");
            navigate("/tobeapproveexpense");
          } else {
            setSpinner(false);
            message.error(res.responseMessage);
          }
        });
      } else {
        setSpinner(false);
        message.error("Request ID not found");
      }
    }
  };
  const onSendBack = () => {
    setSubmitstatus("Send Back...");
    setSpinner(true);
    if (state.id.charAt(0) === "C") {
      if (comment !== undefined && comment !== "") {
        let obj = {
          claimNumber: state.id,
          status: "rejected",
          comment: comment,
          employeeId: sessionStorage.getItem("employeeId"),
        };
        sendBackClaim(obj).then((res) => {
          if (res.responseCode === 200) {
            setSpinner(false);
            message.success("Request has been Send Back Successfully");
            navigate("/tobeapproveexpense");
          } else {
            setSpinner(false);

            message.error(res.responseMessage);
          }
        });
      } else {
        setSpinner(false);

        message.error("Please Add Comment");
      }
    } else {
      if (data.requestId && comment !== undefined && comment !== "") {
        let obj = {
          requestId: data ? data.requestId : "",
          status: "rejected",
          comment: comment,
          employeeId: sessionStorage.getItem("employeeId"),
        };
        sendBackByManager(obj).then((res) => {
          if (res.responseCode === 200) {
            setSpinner(false);

            message.success("Request has been Send Back Successfully");
            navigate("/tobeapproveexpense");
          } else {
            setSpinner(false);

            message.error(res.responseMessage);
          }
        });
      } else {
        setSpinner(false);

        message.error("Please Add Comment");
      }
    }
  };
  console.log(total,advanceNow,advanceRequested)

  return (
    <div style={{ height: "100vh", backgroundColor: "#F7F8FA" }}>
      <Row>
        <Col
          xs={!collapsed ? 19 : 11}
          sm={!collapsed ? 10 : 6}
          md={!collapsed ? 7 : 4}
          lg={!collapsed ? 6 : 5}
          xl={!collapsed ? 5 : 3}
        >
          <div
            style={{ position: "fixed", zIndex: "1" }}
            className={`toggle ${collapsed ? "collapsed" : ""}`}
            onClick={toggleSidebar}
          >
            <svg
              className={`arrow ${collapsed ? "collapsed" : ""}`}
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.39635 2.69038C9.19753 2.49709 8.8752 2.49709 8.67638 2.69038L4.60366 6.64997C4.50818 6.74279 4.45455 6.86869 4.45455 6.99996C4.45455 7.13122 4.50818 7.25712 4.60366 7.34994L8.67638 11.3095C8.8752 11.5028 9.19753 11.5028 9.39635 11.3095C9.59516 11.1162 9.59516 10.8029 9.39635 10.6096L5.6836 6.99996L9.39635 3.39034C9.59516 3.19705 9.59516 2.88367 9.39635 2.69038Z"
                fill="black"
              />
            </svg>
          </div>
          <SideBar
            collapsed={collapsed}
            id={"1"}
            expense={true}
            travel={false}
          />
        </Col>

        <Col
          xs={!collapsed ? 5 : 13}
          sm={!collapsed ? 14 : 18}
          md={!collapsed ? 17 : 20}
          lg={!collapsed ? 18 : 19}
          xl={!collapsed ? 19 : 21}
        >
          <Header expense={true} travel={false} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between", // Aligns items at the start and end of the container
              gap: "1rem",
              marginLeft: "2rem",
              marginTop: "2rem",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              {state.id.charAt(0) == "C" ? (
                <span
                  style={{
                    fontWeight: "700",
                    fontSize: "15px",
                    marginRight: "0.3rem",
                  }}
                >
                  {" "}
                  CLAIM NO. :
                </span>
              ) : (
                <span
                  style={{
                    fontWeight: "700",
                    fontSize: "15px",
                    marginRight: "0.3rem",
                  }}
                >
                  {" "}
                  Request ID :
                </span>
              )}

              <span style={{ fontSize: "15px", color: "#3052D0" }}>
                {state.id ? state.id : ""}
              </span>
            </div>
            {state.id.charAt(0) == "C" ? (
              <>
                {" "}
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      fontWeight: "700",
                      fontSize: "15px",
                      marginRight: "0.3rem",
                    }}
                  >
                    Cash Expense :
                  </span>
                  <span style={{ fontSize: "15px", color: "#3052D0" }}>
                    {cashExpense ? cashExpense : 0}
                  </span>
                </div>{" "}
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      fontWeight: "700",
                      fontSize: "15px",
                      marginRight: "0.3rem",
                    }}
                  >
                    Corp. Card:
                  </span>
                  <span style={{ fontSize: "15px", color: "#3052D0" }}>
                    {corpExpense ? corpExpense : 0}
                  </span>
                </div>
              </>
            ) : (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    fontWeight: "700",
                    fontSize: "15px",
                    marginRight: "0.3rem",
                  }}
                >
                  Request Name :
                </span>
                <span style={{ fontSize: "15px", color: "#3052D0" }}>
                  {data ? data.requestName : ""}
                </span>
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "0.5rem",
              }}
            >
              <Button
                style={{
                  width: "8.5rem",
                  backgroundColor: "#3052D0",
                  border: "none",
                  color: "white",
                }}
                onClick={() => toApprove()}
              >
                Approve
              </Button>
              <Button
                style={{
                  width: "8.5rem",
                  backgroundColor: "transparent",
                  border: "1px solid red",
                  color: "red",
                  marginRight: "0.5rem",
                }}
                onClick={() => onSendBack()}
              >
                Send back
              </Button>
            </div>
          </div>
<div style={{display:"flex",flexDirection:"row"}}>
<div
            style={{
              display: "flex",
              flexDirection: "row",
              marginLeft: "2rem",
            }}
          >
            <span
              style={{
                fontWeight: "700",
                fontSize: "15px",
                marginRight: "0.3rem",
              }}
            >
              Total:
            </span>
             {total < 0 ? (
              <span style={{ color: "red", fontWeight: "600" }}>
                {currency}&nbsp;
                {total}
              </span>
            ) : (
              <span style={{ color: "#1A932E", fontWeight: "600" }}>
                {currency}&nbsp;
                {total}
              </span>
            )} 
            </div>
          {restrictedFlag?.globalFlag?.travelAdvance===1?<> 
          {advanceRequested !== null && advanceRequested !== undefined &&    <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginLeft: "2rem",
            }}
          >
            <span
              style={{
                fontWeight: "700",
                fontSize: "15px",
                marginRight: "0.3rem",
              }}
            >
              Advance Requested:
            </span>
            {advanceRequested == 0 ? (
              <span style={{ color: "#1A932E", fontWeight: "600" }}>
                {currency}&nbsp;
                {advanceRequested}
              </span>
            ) : (
              <span style={{ color: "red", fontWeight: "600" }}>
                {currency}&nbsp;
                -{advanceRequested}
              </span>
            )}
          </div> } 
         {advanceNow !== null && advanceNow !== undefined && <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginLeft: "2rem",
            }}
          >
            <span
              style={{
                fontWeight: "700",
                fontSize: "15px",
                marginRight: "0.3rem",
              }}
            >
              To Be Paid:
            </span>
            {advanceNow < 0 ? (
              <span style={{ color: "red", fontWeight: "600" }}>
                {currency}&nbsp;
                {advanceNow}
              </span>
            ) : (
              <span style={{ color: "#1A932E", fontWeight: "600" }}>
                {currency}&nbsp;
                {advanceNow}
              </span>
            )}
          </div>  }
     </>:<></>}
      
</div>
       
          <div
            style={{
              backgroundColor: "white",
              display: "block",
              padding: "1rem",
              marginRight: "1.5rem",
              borderRadius: "15px",
              marginTop: "1rem",
              height: "61vh",
              // overflow: "auto",
            }}
          >
            <Spin spinning={spinner} tip={submitstatus} size="large">
              <div>
                {state.id.charAt(0) === "C" ? (
                  <div style={{ paddingLeft: "2rem", marginTop: "0.7rem" }}>
                    <Row>
                      <Col lg={15}>
                        <span style={{ fontSize: "15px", fontWeight: "700" }}>
                          Category
                        </span>
                      </Col>
                      <Col lg={9}>
                        <span style={{ fontSize: "15px", fontWeight: "700" }}>
                          Amount
                        </span>
                      </Col>
                    </Row>
                    <hr
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginRight: "10rem",
                      }}
                    />
                    <div
                      style={{
                        height: "33vh",

                        // overflow: "auto",
                      }}
                      className="scrollable-container"
                    >
                      {claimDetails?.map((item, index) => (
                        <Row style={{ marginTop: "0.7rem" }}>
                          <Col lg={15}>
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <FaCircle
                                style={{
                                  color: "#3052D0",
                                  fontSize: "10px",
                                  marginTop: "0.4rem",
                                  marginRight: "0.7rem",
                                }}
                              />
                              <div
                                className=""
                                style={{
                                  border:
                                    "0.596px solid rgba(123, 128, 154, 0.40)",
                                  padding: "5px 0 5px 10px",
                                  width: "70%",
                                  borderRadius: "5px",
                                }}
                              >
                                <span
                                  role="img"
                                  aria-label="Icon"
                                  style={{
                                    marginRight: "8px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleIconClickForClaim(item)}
                                >
                                  <GrStatusInfo style={{ color: "#3052D0" }} />
                                </span>
                                <span
                                  style={{
                                    color: "#344767",
                                    fontWeight: "700",
                                  }}
                                >
                                  {item.category}
                                </span>
                              </div>
                            </div>
                            {/* <span>{item.category}</span>
                            <span
                              role="img"
                              aria-label="Icon"
                              style={{ marginRight: "8px", cursor: "pointer" }}
                              onClick={() => handleIconClickForClaim(item)}
                            >
                              <InfoCircleOutlined
                                style={{ color: "blue", marginLeft: "0.3rem" }}
                              />
                            </span> */}
                          </Col>
                          <Col lg={9}>
                            {item.amount < 0 ? (
                              <span style={{ color: "red", fontWeight: "600" }}>
                                {item.amount}
                              </span>
                            ) : (
                              <span
                                style={{ color: "#1A932E", fontWeight: "600" }}
                              >
                                {item.amount}
                              </span>
                            )}
                          </Col>
                        </Row>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div style={{ paddingLeft: "2rem", marginTop: "1rem" }}>
                    <Row>
                      <Col lg={12}>
                        <span style={{ fontSize: "15px", fontWeight: "700" }}>
                          Category
                        </span>
                      </Col>
                      <Col lg={12}>
                        <span style={{ fontSize: "15px", fontWeight: "700" }}>
                          Amount
                        </span>
                      </Col>
                    </Row>
                    <hr
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginRight: "10rem",
                      }}
                    />
                    <div
                      style={{
                        height: "33vh",
                        // overflow: "auto",
                      }}
                      className="scrollable-container"
                    >
                      {details?.map((item, index) => (
                        <Row style={{ marginTop: "0.7rem" }}>
                          <Col lg={12}>
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <FaCircle
                                style={{
                                  color: "#3052D0",
                                  fontSize: "10px",
                                  marginTop: "0.4rem",
                                  marginRight: "0.7rem",
                                }}
                              />
                              <div
                                className=""
                                style={{
                                  border:
                                    "0.596px solid rgba(123, 128, 154, 0.40)",
                                  padding: "5px 0 5px 10px",
                                  width: "70%",
                                  borderRadius: "5px",
                                }}
                              >
                                <span
                                  role="img"
                                  aria-label="Icon"
                                  style={{
                                    marginRight: "8px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleIconClick(item)}
                                >
                                  <GrStatusInfo style={{ color: "#3052D0" }} />
                                </span>
                                <span
                                  style={{
                                    color: "#344767",
                                    fontWeight: "700",
                                  }}
                                >
                                  {item.expenseType}
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col lg={12}>
                            {item.amount < 0 ? (
                              <span style={{ color: "red", fontWeight: "600" }}>
                                {item.amount}
                              </span>
                            ) : (
                              <span
                                style={{ color: "#1A932E", fontWeight: "600" }}
                              >
                                {item.amount}
                              </span>
                            )}
                          </Col>
                        </Row>
                      ))}
                    </div>
                  </div>
                )}

                <div
                  style={{
                    marginLeft: "1.7rem",
                    marginTop: "0.5rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span style={{ fontWeight: "500", fontSize: "16px" }}>
                    Comment
                  </span>
                  <TextArea
                    // count={{
                    //   show: true,
                    //   max: 1000,
                    //   strategy: (txt) => runes(txt).length,
                    //   exceedFormatter: (txt, { max }) =>
                    //     runes(txt).slice(0, max).join(""),
                    // }}
                    style={{
                      width: "60vw",
                      backgroundColor: "#FAFAFC",
                      marginTop: "0.2rem",
                      resize: "none",
                    }}
                    onChange={(e) => setComment(e.target.value)}
                    rows={3}
                    placeholder="Please describe your comments here"
                  />
                </div>
              </div>
            </Spin>
          </div>
        </Col>
      </Row>
      <Footer />
      {modalOpen && modal ? (
        <DynamicContentModal
          visible={modalOpen}
          onCancel={handleModalCancel}
          dataArray={modalData}
          selectedItem={selectedItem}
        />
      ) : (
        <></>
      )}
      {openpreview ? (
        <Modal
          title={billFileName}
          centered={false}
          style={{ top: "0px" }}
          open={openpreview}
          onCancel={() => {
            setOpenpreview(false);
            setModal(true);
            setPreviewLink();setDownloadLink();
          }}
          // footer={
          //   <Button
          //     onClick={handleDownload}
          //     style={{ backgroundColor: "#7A73FF", color: "white" }}
          //   >
          //     Download Receipt
          //   </Button>
          // }
          footer={false}
          width={800}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              overflow: "auto",
            }}
          >
            {modalFileType === "pdf" ? (
              <iframe
                src={previewlink}
                title="PDF Preview"
                width="100%"
                height="450px"
              />
            ) : (
              <img src={previewlink} alt="Preview" style={previewStyle} />
            )}
          </div>
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
}

export default DetailsExpense;
